import React from 'react'

const ReferEarn = () => {
    return (
        <>
            <div className='page-content'>
            Refer & Earn
            </div>
        </>
    )
}

export default ReferEarn