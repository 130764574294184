import React, { useState, useEffect } from "react";
import user1 from "../../../assets/images/flags/spain.jpg";
import { BellOutlined } from "@ant-design/icons";
import notificationData from "../../../locales/en.json";
import SimpleBar from "simplebar-react";
import "simplebar-react/dist/simplebar.min.css";
import { Menu } from "antd";
import { Button, Dropdown } from "antd";
const NotificationDropdown = () => {
  const [notifications, setNotifications] = useState([]);
  const [dropdownVisible, setDropdownVisible] = useState(false);

  const handleButtonClick = () => {
    setDropdownVisible(!dropdownVisible);
  };
  const handleMenuClick = (e) => {
    // console.log(`Clicked on menu item: ${e.key}`);
    setDropdownVisible(false);
  };
  useEffect(() => {
    setNotifications(notificationData.notifications);
  }, []);

  const [disabled, setDisabled] = useState(200);
  const hendleheght = () => {
    if (disabled === 400) {
      setDisabled(200);
    } else {
      setDisabled(400);
    }
  };
  // console.log(disabled)
  const menu = (
    <Menu onClick={handleMenuClick} className="notification_div">
      <div className="sub_menu_notification">
        <div className="sub_menu-notification_heading">
          <p>Notifications</p>
          <a className="viewall-notification-link">View all</a>
        </div>
        <ul>
          <SimpleBar style={{ maxHeight: disabled, color: "white" }}>
            {notifications.map((notification, key) => {
              return (
                <li className="notification" key={key}>
                  <img src={user1} alt="" className="notificationImg" />
                  <div className="notificationDetails">
                    <p>{notification.notificationHeading}</p>
                    <span>{notification.notification}</span>
                    <small>{notification.time}</small>
                  </div>
                </li>
              );
            })}
          </SimpleBar>
        </ul>
        <Button className="header_notification_btn" type="primary" onClick={hendleheght}>
          View more...
        </Button>
      </div>
    </Menu>
  );

  return (
    <>
      <Dropdown overlay={menu} style={{ inset: " 52px 266px auto auto" }} open={dropdownVisible} onOpenChange={(visible) => setDropdownVisible(visible)} trigger={["click"]}>
        <span onClick={handleButtonClick} className="header-icon-pd">
          <BellOutlined className="icon-header-notification" />
        </span>
      </Dropdown>
    </>
  );
};

export default NotificationDropdown;
