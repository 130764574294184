import React from 'react'
import TableAll from '../../../../Layout/MainTable/Table';

const SalesExcel = () => {
    return (
        <>
            <div className='page-content'>
                <TableAll/>
            </div>
        </>
    )
}

export default SalesExcel;