import React, { useState, useEffect } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { YoutubeOutlined, SnippetsOutlined, CaretDownFilled, UserAddOutlined, SyncOutlined } from "@ant-design/icons";
import { Button, Dropdown, Menu } from "antd";
import { withGeneral } from "../../contexts/generalContext";
import { withRole } from "../../contexts/roleContext";
import { withProfile } from "../../contexts/profileContext";

const TopBar = ({ opencomponent, role, validatedProfile }) => {
  const navigate = useNavigate();
  const location = useLocation();
  const currentPath = location.pathname;
  const pathnameonly = currentPath.substring(1);

  const uploadButtonAvilablePath = ["/bulk-upload", "/sales/excel", "/banking", "/sales-return", "/purchase/excel", "/purchase-return", "/journal", "/ledgerbulk", "/item/excel", "/ledgermaster", "/item"];

  const getItemsBasedOnPath = () => {
    const pathsToCheckbulk = ["/bulk-upload", "/sales/excel", "/banking", "/sales-return", "/purchase/excel", "/purchase-return", "/journal", "/ledgerbulk", "/item/excel"];
    const pathsToChecksettings = ["/settings", "/adduser", "/my-profile", "/my-plan", "/users", "/user-report", "/refer-earn", "/role-management"];
    const pathsToCheckmaster = ["/master", "/ledgermaster", "/item", "/ruelist", "/leadgergroupnames", "/ledgernames", "/stockgroup", "/stockitem", "/unit", "/vouchertype"];
    const pathsToChecktransactions = ["/transactions", "/tc-payment", "/tc-sales", "/tc-purchase", "/tc-payment", "/tc-receipt", "/tc-contra"];
    const pathsToCheckMyCompany = ["/my-companies", "/add-companies"];
    const pathsToCheckAutosale = ["/autosale"];

    if (pathsToCheckbulk.some((path) => currentPath.startsWith(path))) {
      return BulkUpload;
    } else if (pathsToChecksettings.some((path) => currentPath.startsWith(path))) {
      return Settings;
    } else if (pathsToCheckmaster.some((path) => currentPath.startsWith(path))) {
      return Master;
    } else if (pathsToChecktransactions.some((path) => currentPath.startsWith(path))) {
      return Transactions;
    } else if (pathsToCheckMyCompany.some((path) => currentPath.startsWith(path))) {
      return MyCompany;
    } else if (pathsToCheckAutosale.some((path) => currentPath.startsWith(path))) {
      return Autosale;
    }

    return [];
  };

  const BulkUpload = [
    { link: "/banking", component: "Banking" },
    { link: "/sales/excel", component: "Sales" },
    // { link: "/sales-return", component: "Sales-Return" },
    { link: "/purchase/excel", component: "Purchase" },
    // { link: "/purchase-return", component: "Purchase-Return" },
    // { link: "/journal", component: "Journal" },
    { link: "/ledgerbulk", component: "Ledger" },
    { link: "/item/excel", component: "Items" },
  ];

  const Autosale = [{ link: "/autosale", component: "Autosale" }];

  const Settings =
    validatedProfile.userType === "S"
      ? [{ link: "/my-profile", component: "My profile" }]
      : [
          { link: "/my-profile", component: "My profile" },
          { link: "/my-plan", component: "My plan" },
          { link: "/users", component: "Users" },
          { link: "/refer-earn", component: "Refer earn" },
          { link: "/role-management", component: "Role management" },
        ];

  const Master = [
    { link: "/ledgermaster", component: "Ledger" },
    { link: "/item", component: "Item" },
    { link: "/ruelist", component: "Rule List" },
    // { link: "/leadgergroupnames", component: "leadgergroupnames" },
    // { link: "/ledgernames", component: "ledgernames" },
    // { link: "/stockgroup", component: "stockgroup" },
    // { link: "/stockitem", component: "stockitem" },
    // { link: "/unit", component: "unit" },
    // { link: "/vouchertype", component: "vouchertype" },
  ];

  const Transactions = [
    { link: "/tc-sales", component: "Sales" },
    { link: "/tc-purchase", component: "Purchase" },
    { link: "/tc-payment", component: "Payment" },
    { link: "/tc-receipt", component: "Receipt" },
    { link: "/tc-contra", component: "Contra" },
  ];

  const MyCompany = [
    { link: "/my-companies", component: "My Companies" },
    { link: "/add-companies", component: "Add Companies" },
  ];

  // const [isSubMenuOpen, setIsSubMenuOpen] = useState(false);
  // const [activeItem, setActiveItem] = useState(0);
  // const handleItemClick = (index) => {
  //   setActiveItem(index);
  // };

  const [dropdownVisible, setDropdownVisible] = useState(false);

  const handleButtonClick = () => {
    setDropdownVisible(!dropdownVisible);
  };

  const handleMenuClick = (e) => {
    console.log(`Clicked on menu item: ${e.key}`);
    setDropdownVisible(false);
  };
  // console.log("currentPath", currentPath);
  const menu = (
    <div className="topbar-download-btn">
      <Menu onClick={handleMenuClick}>
        <Menu.Item key="item1">
          <a href="#">Item Invoice(With Item)</a>
        </Menu.Item>
        <Menu.Item key="item2">
          <a href="#">Accounting Invoice(Without Item)</a>
        </Menu.Item>
      </Menu>
    </div>
  );

  const renderUploadButton = () => {
    for (let rolekey in role) {
      if (uploadButtonAvilablePath.some((path) => path === currentPath) && currentPath === rolekey && (role[rolekey] === "F" || role[rolekey] === "CR")) {
        return (
          <div className="upload-btn all-div-margin" key={rolekey}>
            <button className="bulkupload-topbar-btn bulkupload-btn btn-primary border-primary" onClick={() => opencomponent(pathnameonly)}>
              Upload
            </button>
          </div>
        );
      }
    }
    return null;
  };

  useEffect(() => {
    const items = getItemsBasedOnPath();
    let shouldNavigate = true;

    items.forEach((component) => {
      for (let rolekey in role) {
        if (component.link === rolekey && role[rolekey] !== null) {
          if ((currentPath === "/bulk-upload" || currentPath === "/master" || currentPath === "/transactions" || currentPath === "/settings") && shouldNavigate) {
            navigate(rolekey);
            shouldNavigate = false;
          }
        }
      }
    });
  }, [currentPath, role, navigate]);

  return (
    <div className="bulkupload-topbar">
      <div className="bulkupload-topbar-inner">
        <div className="bulkupload-topbar-header">
          <div className="bulkupload-topbar-header-ul d-flex">
            {getItemsBasedOnPath().map((component, index) => {
              const matchingRoleKey = Object.keys(role).find((rolekey) => component.link === rolekey);

              if (matchingRoleKey && validatedProfile.userType === "S") {
                if (role[matchingRoleKey] !== null) {
                  const lowercasePath = component.link;
                  return (
                    <li key={index} className={`bnk-hd-li ${currentPath === lowercasePath ? "active" : ""}`}>
                      <Link className="bnk-hd-li-link" to={component.link}>
                        {component.component}
                      </Link>
                    </li>
                  );
                }
              } else {
                const lowercasePath = component.link;
                return (
                  <li key={index} className={`bnk-hd-li ${currentPath === lowercasePath ? "active" : ""}`}>
                    <Link className="bnk-hd-li-link" to={component.link}>
                      {component.component}
                    </Link>
                  </li>
                );
              }

              return null;
            })}
          </div>
          <div className="bulkupload-topbar-header-btn">
            {currentPath === "/users" && (
              <div className="upload-btn all-div-margin">
                <Link to="/adduser" className="btn-text">
                  <button className="bulkupload-topbar-btn bulkupload-btn btn-primary border-primary">
                    <UserAddOutlined className="text-success pr-1" />
                    Add User
                  </button>
                </Link>
              </div>
            )}
            {currentPath === "/my-companies" && (
              <div className="upload-btn all-div-margin">
                <button className="bulkupload-topbar-btn bulkupload-btn btn-primary border-primary">
                  <SyncOutlined className="text-success pr-1" />
                  Sync Companies
                </button>
              </div>
            )}
            {currentPath !== "/my-companies" && (
              <div className="download-btn all-div-margin">
                <Dropdown overlay={menu} open={dropdownVisible} onOpenChange={(visible) => setDropdownVisible(visible)} trigger={["click"]}>
                  <Button onClick={handleButtonClick} className="Download-Sample-btn">
                    Download Sample <CaretDownFilled className="header-admin-icon w-5" />
                  </Button>
                </Dropdown>
              </div>
            )}
            {renderUploadButton()}
            {currentPath === "/autosale" && (
              <div className="upload-btn all-div-margin">
                <button className="bulkupload-topbar-btn bulkupload-btn btn-primary border-primary" onClick={() => opencomponent(pathnameonly)}>
                  Upload Autosale
                </button>
              </div>
            )}
            <div className="youtube-btn all-div-margin">
              <button className="bulkupload-topbar-btn bulkupload-btn btn-outline-danger bg-btn">
                <YoutubeOutlined className="topobar-icon text-danger" />
              </button>
            </div>
            <div className="document-btn all-div-margin">
              <button className="bulkupload-topbar-btn bulkupload-btn btn-outline-primary bg-btn">
                <SnippetsOutlined className="topobar-icon" />
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default withGeneral(withProfile(withRole(TopBar)));
