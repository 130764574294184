import React from "react";
import { Link, useLocation } from "react-router-dom";
const UserReport = () => {
  const location = useLocation();
  const currentPath = location.pathname.substring(1);
  return (
    <>
      <div className="setting-user">
        <div className="user-heading">
          <ul className="user-heading-ul">
            <li className={`user-heading-li ${currentPath === "users" ? "active" : ""}`}>
              <Link className="user-heading-li-a" to="/users">
                User List
              </Link>
            </li>
            <li className={`user-heading-li  ${currentPath === "user-report" ? "active" : ""}`}>
              <Link className="user-heading-li-a" to="/user-report">
                User Report
              </Link>
            </li>
          </ul>
        </div>
      </div>

      <div className="page-content">
        <h2>Hello User Report</h2>
      </div>
    </>
  );
};

export default UserReport;
