import React, { useState, useEffect } from 'react';

const Settings = () => {
    return (
        <>
            <div className='page-content'>
                Settings
            </div>
        </>
    )
}

export default Settings