import React from "react";
import { Navigate } from "react-router-dom";

import Login from "../components/Auth/Login";
import SignInWithOtp from "../components/Auth/SignInWithOtp";
import ForgotPass from "../components/Auth/ForgotPass";
import Register from "../components/Auth/Register";

import Dashboard from "../components/Pages/Dashboard";
import Analysis from "../components/Pages/Analysis";
import Reports from "../components/Pages/Reports";
import BulkUpload from "../components/Pages/BulkUpload";
import Master from "../components/Pages/Master";
import Transactions from "../components/Pages/Transactions";
import GstRecord from "../components/Pages/GstRecord";
import Document from "../components/Pages/Document";
import Settings from "../components/Pages/Settings";
import Learn from "../components/Pages/Learn";

import LedgerExcel from "../components/Pages/BulkUpload/Ledger";
import Banking from "../components/Pages/BulkUpload/Banking";
import Journal from "../components/Pages/BulkUpload/Journal";
import ItemsExcel from "../components/Pages/BulkUpload/Items";
import PurchaseReturn from "../components/Pages/BulkUpload/PurchaseReturn";
import SalesReturn from "../components/Pages/BulkUpload/SalesReturn";
import PurchaseExcel from "../components/Pages/BulkUpload/Purchase";
import SalesExcel from "../components/Pages/BulkUpload/Sales";

import Ledger from "../components/Pages/Master/Ledger";
import Ruelist from "../components/Pages/Master/Ruelist";
import Item from "../components/Pages/Master/Item";

import Sales from "../components/Pages/Transactions/Sales";
import Contra from "../components/Pages/Transactions/Contra";
import Payment from "../components/Pages/Transactions/Payment";
import Receipt from "../components/Pages/Transactions/Receipt";
import Purchase from "../components/Pages/Transactions/Purchase";

import MyProfile from "../components/Pages/Settings/MyProfile";
import MyPlan from "../components/Pages/Settings/MyPlan";
import ReferEarn from "../components/Pages/Settings/ReferEarn";
import RoleManagement from "../components/Pages/Settings/RoleManagement";
import Users from "../components/Pages/Settings/Users";
import UserReport from "../components/Pages/Settings/Users/UserReport/UserReport";
import ChangePass from "../components/Auth/changepassword";

import MyCompany from "../components/Pages/MyCompany";
import AddCompany from "../components/Pages/MyCompany/AddCompany";
import { Leadgergroupnames, Ledgernames, Stockgroup, Stockitem, Unit, Vouchertype } from "../components/Pages/Master/all";
import TransactionsTable from "../Layout/SubTable/TransactionsTable";
import Mapping from "../Layout/Mapping/Mapping";
import AutoSale from "../components/Pages/AutoSale";
import AddRole from "../components/Pages/Settings/RoleManagement/AddRole";
import AddUser from "../components/Pages/Settings/Users/AddUser";

const commonRoutes = [
  // pages
  { path: "/dashboard", component: <Dashboard /> },
  { path: "/analysis", component: <Analysis /> },
  { path: "/reports", component: <Reports /> },
  { path: "/bulk-upload", component: <BulkUpload /> },
  { path: "/master", component: <Master /> },
  { path: "/transactions", component: <Transactions /> },
  { path: "/gst-record", component: <GstRecord /> },
  { path: "/document", component: <Document /> },
  { path: "/settings", component: <Settings /> },
  { path: "/learn", component: <Learn /> },

  // additional pages
  { path: "/banking", component: <Banking /> },
  { path: "/journal", component: <Journal /> },
  { path: "/purchase/excel", component: <PurchaseExcel /> },
  { path: "/purchase-return", component: <PurchaseReturn /> },
  { path: "/sales/excel", component: <SalesExcel /> },
  { path: "/sales-return", component: <SalesReturn /> },
  { path: "/ledgerbulk", component: <LedgerExcel /> },
  { path: "/item/excel", component: <ItemsExcel /> },

  // master page
  { path: "/ledgermaster", component: <Ledger /> },
  { path: "/item", component: <Item /> },
  { path: "/ruelist", component: <Ruelist /> },
  { path: "/leadgergroupnames", component: <Leadgergroupnames /> },
  { path: "/ledgernames", component: <Ledgernames /> },
  { path: "/stockgroup", component: <Stockgroup /> },
  { path: "/stockitem", component: <Stockitem /> },
  { path: "/unit", component: <Unit /> },
  { path: "/vouchertype", component: <Vouchertype /> },

  // transaction pages
  { path: "/tc-sales", component: <Sales /> },
  { path: "/tc-purchase", component: <Purchase /> },
  { path: "/tc-payment", component: <Payment /> },
  { path: "/tc-receipt", component: <Receipt /> },
  { path: "/tc-contra", component: <Contra /> },

  // settings pages
  { path: "/my-profile", component: <MyProfile /> },

  // MyCompany pages
  { path: "/my-companies", component: <MyCompany /> },
  { path: "/add-companies", component: <AddCompany /> },

  // other
  { path: "/transactionstable", component: <TransactionsTable /> },
  { path: "/mapping", component: <Mapping /> },
  { path: "/autosale", component: <AutoSale /> },

  // redirect to dashboard
  { path: "/", exact: true, component: <Navigate to="/dashboard" /> },
];

const authProtectedRoutes = [
  ...commonRoutes,
  // additional
  { path: "/my-plan", component: <MyPlan /> },
  { path: "/refer-earn", component: <ReferEarn /> },
  { path: "/role-management", component: <RoleManagement /> },
  { path: "/addrole", component: <AddRole /> },
  { path: "/users", component: <Users /> },
  { path: "/user-report", component: <UserReport /> },
  { path: "/adduser", component: <AddUser /> },
];

const subUserAuthProtectedRoutes = [...commonRoutes];

const publicRoutes = [
  // Authentication Page
  { path: "/login", component: <Login /> },
  { path: "/signIn-with-otp", component: <SignInWithOtp /> },
  { path: "/forgot-password", component: <ForgotPass /> },
  { path: "/change-Password", component: <ChangePass /> },
  { path: "/register", component: <Register /> },
];

export { authProtectedRoutes, publicRoutes, subUserAuthProtectedRoutes };
