import React from "react";
import TableAll from "../../../../Layout/MainTable/Table";

const Leadgergroupnames = () => {
  return (
    <>
      <div className="page-content">
        <TableAll />
      </div>
    </>
  );
};

const Ledgernames = () => {
  return (
    <>
      <div className="page-content">
        <TableAll />
      </div>
    </>
  );
};
const Stockgroup = () => {
  return (
    <>
      <div className="page-content">
        <TableAll />
      </div>
    </>
  );
};

const Stockitem = () => {
  return (
    <>
      <div className="page-content">
        <TableAll />
      </div>
    </>
  );
};
const Unit = () => {
  return (
    <>
      <div className="page-content">
        <TableAll />
      </div>
    </>
  );
};

const Vouchertype = () => {
  return (
    <>
      <div className="page-content">
        <TableAll />
      </div>
    </>
  );
};

export { Leadgergroupnames, Ledgernames, Stockgroup, Stockitem, Unit, Vouchertype };
