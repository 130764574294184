import React, { useState } from "react";
import PropTypes from "prop-types";
import { Link, useLocation } from "react-router-dom";
import withRouter from "../../components/Common/withRouter";

import "simplebar-react/dist/simplebar.min.css";
import SidebarData from "./SidebarData";
import { withRole } from "../../contexts/roleContext";
import { withProfile } from "../../contexts/profileContext";
import { logDOM } from "@testing-library/dom";

const Sidebar = ({ role, validatedProfile }) => {
  const [open, setopen] = useState(false);
  const currentPath = useLocation().pathname;

  return (
    <div className="vertical-menu-small">
      <div id="sidebar-menu-small">
        <ul className="metismenu-small list-unstyled-small" id="side-menu-item-small">
          {SidebarData?.map((item, mapKey) => {
            let shouldRenderRoute = true;
            let matchingRoleKey = role ? Object.keys(role).find((rolekey) => item.url === rolekey) : null;
            if (matchingRoleKey) {
              if (role[matchingRoleKey] === null && validatedProfile.userType === "S") {
                shouldRenderRoute = false;
              }
            }

            if (shouldRenderRoute) {
              return (
                <li key={mapKey} id="side-menu-small-itemp-li" className={currentPath === item.url || (item.subItem && item.subItem.some((subitem) => currentPath === subitem.link)) ? "active" : ""} onClick={() => setopen(mapKey)}>
                  <div className="icon-container-small">
                    <Link to={item.url} className="single-icon-small">
                      {item.icon} <small>{item.label}</small>{" "}
                    </Link>
                  </div>

                  {item.subItem && (
                    <ul className="sub-menu-small ">
                      {item.subItem.map((subMenuItem, key) => {
                        let matchingRoleKey = role ? Object.keys(role).find((rolekey) => subMenuItem.link === rolekey) : null;
                        if (matchingRoleKey && validatedProfile.userType === "S") {
                          if (role[matchingRoleKey] !== null) {
                            return (
                              <li key={key}>
                                <Link to={subMenuItem.link} className="sub-item-hover-small">
                                  {subMenuItem.sublabel}
                                </Link>
                              </li>
                            );
                          }
                        } else {
                          if (validatedProfile.userType === "S" && (subMenuItem.link === "/my-plan" || subMenuItem.link === "/users" || subMenuItem.link === "/refer-earn" || subMenuItem.link === "/role-management")) {
                            return;
                          }
                          return (
                            <li key={key}>
                              <Link to={subMenuItem.link} className="sub-item-hover-small">
                                {subMenuItem.sublabel}
                              </Link>
                            </li>
                          );
                        }
                      })}
                    </ul>
                  )}
                </li>
              );
            } else {
              return null;
            }
          })}
        </ul>
      </div>
    </div>
  );
};

Sidebar.propTypes = {
  location: PropTypes.object,
};

export default withRouter(withProfile(withRole(Sidebar)));
