import React from 'react'

const Reports = () => {
  return (
    <>
      <div className='page-content'>
        reports
      </div>
    </>
  )
}

export default Reports