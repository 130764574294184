import React, { useState } from 'react';
// import logoDark from '../../../assets/images/logo-dark.png';
import logoDark from "../../../assets/images/45x45.png";
import { Alert } from 'antd'

const ForgotPass = () => {
    const [email, setEmail] = useState();

    const handleSubmit = (e) => {
        e.preventDefault();
        console.log("email", email);
    }

    return (
        <>
            <div className='bg-overlay'></div>
            <div className='auth-bg'>
                <div className='auth-page'>
                    <div>
                        <div>
                            <img src={logoDark} alt='logo' />
                        </div>
                        <h4 className='text-muted mt-2' style={{ fontSize: 18 }}>Reset Password</h4>
                        <span className='text-muted'>Reset your Password with Tax-link.</span>
                    </div>
                    <div className='text-left mt-3'>
                        <Alert
                            description={<span>Enter your <b>Email</b> and instructions will be sent to you !</span>}
                            type="warning"
                            closable
                            className='align-items-center'
                        />
                    </div>
                    <div className='text-left mt-3'>
                        <form action='#' onSubmit={handleSubmit}>
                            <div className="mb-3">
                                <label className="form-label" htmlFor="Email">Email<b className='text-danger'>*</b></label>
                                <input type="text" className="form-control" id="Email" placeholder="Enter email" required onChange={(e) => setEmail(e.target.value)} />
                            </div>
                            <div className="mt-3">
                                <button className="btn btn-primary w-100" type="submit">Send Email</button>
                            </div>
                        </form>
                    </div>
                    <div className="text-center mt-3">
                        <span className="text-muted">Remembered password, back to <a className='text-primary' href="/login"> Sign In </a> </span>
                    </div>
                </div>
            </div>
        </>
    )
}

export default ForgotPass;