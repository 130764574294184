import React from 'react'

const Document = () => {
    return (
        <>
            <div className='page-content'>
                Document
            </div>
        </>
    )
}

export default Document;