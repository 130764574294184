import React, { useState, useEffect } from "react";

const Transactions = () => {
  return (
    <>
      <div className="page-content">
        <div>Transactions</div>
      </div>
    </>
  );
};

export default Transactions;
