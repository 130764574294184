import React, { useState, useEffect } from 'react';

const Master = () => {
    return (
        <>
            <div className='page-content'>
                <div>Master</div> 
            </div>
        </>
    )
}

export default Master;