import React from 'react';
import Routes from "./Routes/index";

function App() {
  return (
    <>
      <Routes />
    </>
  );
}

export default App;
