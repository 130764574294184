import React from 'react'

const Purchase = () => {
    return (  
        <>
            <div className='page-content'>
                <div>Purchase</div> 
            </div>
        </>
    )
}

export default Purchase;