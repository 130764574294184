import React from 'react'

const Learn = () => {
    return (
        <>
            <div className='page-content'>
                Learn
            </div>
        </>
    )
}

export default Learn;