import React, { useState, useEffect } from "react";
import { Modal, Select } from "antd";
import axios from "axios";
import { FileUploader } from "react-drag-drop-files";
import { withProfile } from "../../../../contexts/profileContext";
import { PaperClipOutlined } from "@ant-design/icons";

const dataArray = [{ name: "State Bank Of India" }, { name: "Bank Of Baroda" }, { name: "SBM NIYO GLOBAL" }, { name: "ICICI Credit Card" }, { name: "DCB" }, { name: "AXIS SAVING" }, { name: "AXIS CURRENT" }, { name: "KOTAK BANK" }, { name: "IDFC BANK" }, { name: "FEDRAL BANK" }];

const BankingAll = ({ validatedProfile, pathWithoutSlash, selectedBankName, setSelectedBankName, selectedDataArrayBank, handleChange, handleBankOptionSelect, name, modal2Open, handleOkClick, handleCancel }) => {
  const fileTypes = ["xlsx", "xls", "pdf", "csv"];
  const [bankoption, setBankoption] = useState([]);
  const [fileTitle, setFileTitle] = useState("");

  useEffect(() => {
    if (validatedProfile.companyId) {
      viewAll();
    }
  }, [validatedProfile.companyId]);

  const viewAll = () => {
    axios
      .post(`${process.env.REACT_APP_API_URL}/subtabledata`, {
        userId: validatedProfile.userType === "S" ? validatedProfile.parentid : validatedProfile.userid,
        companyId: validatedProfile.companyId,
      })
      .then((response) => {
        if (response.status === 200) {
          const newOptions = response.data.results["ledgermaster"].filter((element) => element.under === "Bank Accounts").map((element) => element.name);

          const uniqueOptions = Array.from(new Set(newOptions));
          setBankoption(uniqueOptions);
        }
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  };

  const handleFileChange = (file) => {
    handleChange(file);
    setFileTitle(file[0].name);
    console.log("ffff", file[0].name);
  };

  return (
    <>
      <Modal title={`Upload ${name}`} centered open={modal2Open} onOk={handleOkClick} onCancel={handleCancel} width={650}>
        <div className="upl-page-body">
          {pathWithoutSlash === "banking" ? (
            <>
              <div className="banking-dropdoun-in-fileupload">
                <select className="banking-dropdown" onChange={(event) => setSelectedBankName(event.target.value)} value={selectedBankName}>
                  <option value="">Select Bank</option>
                  {bankoption.map((bank, index) => (
                    <option key={index} value={bank}>
                      {bank}
                    </option>
                  ))}
                </select>
                <select className="banking-dropdown" onChange={(event) => handleBankOptionSelect(event.target.value)} value={selectedDataArrayBank}>
                  <option value="">Select Bank PDF</option>
                  {dataArray.map((bank, index) => (
                    <option key={index} value={bank.name}>
                      {bank.name}
                    </option>
                  ))}
                </select>
              </div>

              {/* <div className="banking-dropdoun-in-fileupload"></div> */}
            </>
          ) : null}

          <form action="">
            <div className="upload-file">
              <FileUploader multiple={true} handleChange={handleFileChange} name="file" types={fileTypes} className="upload-file-btn" />
            </div>
            <div className="note">
              <div className="note-ledger">
                {fileTitle && (
                  <p className="fileTitle_text">
                    <PaperClipOutlined style={{ fontSize: "20px", color: "#1890ff", margin: "0 8px" }} />
                    {fileTitle}
                  </p>
                )}
                <p>Notes:</p>
                <ul>
                  <li>Please make sure the uploaded excel file does not contain the dot(.) and dollar($) symbol in the column header and other than sales/purchase field do not add anything above header.</li>
                  <li>Please make sure the file size must not exceed 30MB.</li>
                  <li>Sync the ledger before uploading the file.</li>
                  <li>Please do not upload password-protected excel files.</li>
                  <li>Date format should be DD/MM/YYYY.</li>
                </ul>
              </div>
            </div>
          </form>
        </div>
      </Modal>
    </>
  );
};

export default withProfile(BankingAll);
