import React, { createContext, useState, useEffect, useContext } from "react";
import axios from "axios";
import { isEmpty } from "lodash";
import { ProfileContext } from "./profileContext";

const RoleContext = createContext();

const RoleProvider = ({ children }) => {
  const [role, setRole] = useState(null);
  const [roleWithoutSlash, setRoleWithoutSlash] = useState(null);
  const [loading, setLoading] = useState(true); // Initial state set to true to ensure loading
  const { validatedProfile } = useContext(ProfileContext);

  useEffect(() => {
    const getRoleData = async () => {
      if (!validatedProfile || isEmpty(validatedProfile)) {
        // console.log("No validated profile available.");
        setLoading(false); // Avoid being stuck in a loading state
        return;
      }

      const id = { srno: validatedProfile.roleId };

      try {
        const response = await axios.post(`${process.env.REACT_APP_API_URL}/getRole`, id);
        // console.log("Response from API:", response);
        const data = response.data?.userDetails?.[0];

        if (!data) {
          console.error("No role data found.");
          setLoading(false);
          return;
        }

        // Modifying role with slashes
        const modifiedRole = Object.keys(data).reduce((acc, key) => {
          const newKey = `/${key.split("_").join("-")}`;
          acc[newKey] = data[key];
          return acc;
        }, {});

        // Modifying role without slashes
        const modifiedRoleWithoutSlash = Object.keys(data).reduce((acc, key) => {
          const newKey = key.split("_").join("-");
          acc[newKey] = data[key];
          return acc;
        }, {});

        // Update state and local storage
        setRole(modifiedRole);
        setRoleWithoutSlash(modifiedRoleWithoutSlash);
        localStorage.setItem("role", JSON.stringify(modifiedRole));
        localStorage.setItem("roleWithoutSlash", JSON.stringify(modifiedRoleWithoutSlash));
      } catch (error) {
        console.error("Error fetching role data:", error);
      } finally {
        setLoading(false); // Loading complete
      }
    };

    // Only fetch role data if it's not already in localStorage
    const storedRole = localStorage.getItem("role");
    const storedRoleWithoutSlash = localStorage.getItem("roleWithoutSlash");

    if (!storedRole || !storedRoleWithoutSlash) {
      getRoleData(); // Fetch data if not available in local storage
    } else {
      setRole(JSON.parse(storedRole));
      setRoleWithoutSlash(JSON.parse(storedRoleWithoutSlash));
      setLoading(false); // Data is already available, no need to fetch
    }
  }, [validatedProfile]);

  if (loading) {
    return <div>Loading roles...</div>;
  }

  return (
    <RoleContext.Provider
      value={{
        role,
        setRole,
        roleWithoutSlash,
      }}
    >
      {children}
    </RoleContext.Provider>
  );
};

const withRole = (Child) => (props) => <RoleContext.Consumer>{(context) => <Child {...props} {...context} />}</RoleContext.Consumer>;

export { RoleProvider, withRole };
