import { Modal } from "antd";
import React from "react";
import taxlink_animation from "../../../assets/images/taxlink_animation.png";

const Spinner = ({ isSpinner }) => {
  return (
    <Modal className="icon-loader-model" data-backdrop="false" centered open={isSpinner} width="auto" footer={null} closable={false}>
      <img className="icon-loader" src={taxlink_animation} alt="loading..." />
    </Modal>
  );
};

export default Spinner;
