export const Pages = [
    {
        name: "dashboard",
    },
    {
        name: "analysis",
    },
    {
        name: "reports",
    },
    {
        name: "bulk-upload",
    },
    {
        name: "master",
    },
    {
        name: "transactions",
    },
    {
        name: "gst-record",
    },
    {
        name: "document",
    },
    {
        name: "settings",
    },
    {
        name: "learn",
    },
]