import React, { useEffect, useState } from "react";
import { Button, Dropdown, Modal } from "antd";
import { CaretDownFilled, BankOutlined, CheckCircleFilled } from "@ant-design/icons";
import { List } from "antd";
import { Navigate, useNavigate } from "react-router-dom";
import axios from "axios";
import SimpleBar from "simplebar-react";
import "simplebar-react/dist/simplebar.min.css";
import { Menu } from "antd";
import Cookies from "js-cookie";
import CryptoJS from "crypto-js";

import { withProfile } from "../../../contexts/profileContext";

const secretKey = "L3j*8k!2O#dM9wQ4T$7eV6rX0fN1pG@5";

const MyCompany = ({ setValidatedProfile, validatedProfile }) => {
  const navigate = useNavigate();
  const [companys, setcompanys] = useState([]);
  const [dropdownVisible, setDropdownVisible] = useState(false);
  const [selectedCompanyName, setSelectedCompanyName] = useState("");

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = async () => {
    try {
      let response;

      if (validatedProfile.userType === "P") {
        const userId = validatedProfile.userid;

        const data = {
          userId: userId,
        };
        response = await axios.post(`${process.env.REACT_APP_API_URL}/company-data`, data, {
          headers: {
            "Content-Type": "application/json",
          },
        });
      } else {
        const userId = validatedProfile.userid;

        const data = {
          userId: userId,
        };

        response = await axios.post(`${process.env.REACT_APP_API_URL}/companydata-bycompanyid`, data, {
          headers: {
            "Content-Type": "application/json",
          },
        });
      }

      setcompanys(response.data.getCompanyDatas && response.data.getCompanyDatas.map((item) => item));

      setSelectedCompanyName({ companyId: response.data.getCompanyDatas[0].company_id, companyName: response.data.getCompanyDatas[0].company_name });

      if (!validatedProfile.companyId) {
        setSelectedCompanyName(response.data.getCompanyDatas[0].company_name);
        const updatedProfile = {
          ...validatedProfile,
          companyId: response.data.getCompanyDatas[0].company_id,
          companyName: response.data.getCompanyDatas[0].company_name,
        };
        setValidatedProfile(updatedProfile);
        // sessionStorage.setItem("validatedProfile", JSON.stringify(updatedProfile));

        try {
          const encryptedProfile = CryptoJS.AES.encrypt(JSON.stringify(updatedProfile), secretKey).toString();
          Cookies.set("validatedProfile", encryptedProfile, {
            expires: 7, // Cookie expires in 7 days
          });
        } catch (error) {
          console.error("Error encrypting profile data:", error);
        }
      }
    } catch (error) {
      console.error("Error:", error.message);
    }
  };

  const handleButtonClick = () => {
    setDropdownVisible(!dropdownVisible);
  };

  const ManageButtonClick = () => {
    setDropdownVisible(!dropdownVisible);
    navigate("/my-companies");
  };

  const hendelcompany = (companyId, companyName) => {
    const confirmed = window.confirm(`Are you sure you want to Change "${companyName}"?`);

    if (confirmed) {
      const updatedProfile = {
        ...validatedProfile,
        companyId: companyId,
        companyName: companyName,
      };
      setValidatedProfile(updatedProfile);

      // sessionStorage.setItem("validatedProfile", JSON.stringify(updatedProfile));
      setDropdownVisible(!dropdownVisible);
    }
  };
  const handleCancel = () => {
    setDropdownVisible(false);
  };
  return (
    <>
      <span onClick={handleButtonClick} className="company-icon header-icon-pd">
        <p className="mycompany-icon-btn">
          {"| "}
          <span className="mycompany-name-text">
            {validatedProfile.companyName && validatedProfile.companyName.toUpperCase()} <CaretDownFilled className="header-admin-icon w-5" />
          </span>
          {" |"}
        </p>
      </span>

      <Modal className="companylist-model" open={dropdownVisible} okButtonProps={{ style: { display: "none" } }} onCancel={handleCancel}>
        <div className="company-dropdown-outer">
          <div className="company-dropdown bg-white">
            <div className="dropdown-body">
              <div className="dropdown-body-content">
                <div className="dropdown-body-header">
                  <p>My Company</p>
                  <Button type="primary" className="" onClick={ManageButtonClick}>
                    Manage
                  </Button>
                </div>
                <SimpleBar style={{ maxHeight: "calc(90vh - 50px)" }}>
                  <List
                    size="large"
                    dataSource={companys}
                    renderItem={(item) => (
                      <List.Item className={validatedProfile.companyId === item.company_id ? "active " : ""} onClick={() => hendelcompany(item.company_id, item.company_name)}>
                        <div className="d-flex ">
                          <BankOutlined className="header-company-logo" />
                          <a className="company-select-icon">{item.company_name.toUpperCase()}</a>
                        </div>
                        {validatedProfile.companyId === item.company_id ? <CheckCircleFilled className="text-success" /> : ""}
                      </List.Item>
                    )}
                  />
                </SimpleBar>
              </div>
            </div>
          </div>
        </div>
      </Modal>
    </>
  );
};

export default withProfile(MyCompany);
