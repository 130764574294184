import React from "react";
import TableAll from "../../../Layout/MainTable/Table";

const AutoSale = () => {
  return (
    <>
      <div className="page-content">
        <TableAll />
      </div>
    </>
  );
};

export default AutoSale;
