import React from 'react'

const Sales = () => {
    return (  
        <>
            <div className='page-content'>
                <div>Sales</div> 
            </div>
        </>
    )
}

export default Sales;