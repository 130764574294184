import { useLocation, useNavigate, useParams } from "react-router-dom";

function withRouter(Component) {
  function ComponentWithRouterProp(props) {
    let location = useLocation();
    let navigate = useNavigate();
    let params = useParams();
    const pathWithoutSlash = location.pathname.slice(1);
    const pathOnlyName = pathWithoutSlash.split("/")[0];

    return <Component {...props} router={{ location, navigate, params }} urlPathName={{ pathnameWithSlash: location.pathname, pathWithoutSlash: pathWithoutSlash, pathOnlyName: pathOnlyName }} />;
  }

  return ComponentWithRouterProp;
}

export default withRouter;
