import React, { useEffect, useState } from "react";
import { FaLocationArrow } from "react-icons/fa";
import {
  CloseCircleOutlined,
  CheckCircleOutlined,
  CaretRightOutlined,
} from "@ant-design/icons";
// import '../index.css';
import SimpleBar from "simplebar-react";
import "simplebar-react/dist/simplebar.min.css";
import logoDark from "../assets/images/logo-dark.png";
import logoLight from "../assets/images/logo-light.png";
import chaticon from "../assets/images/talk.png";
import chaticonclose from "../assets/images/cross.png";
// import { RightCircleOutlined } from 'antd';

const ChatButton = () => {
  const [isFormVisible, setIsFormVisible] = useState(false);

  const toggleChatbot = () => {
    setIsFormVisible(!isFormVisible);
  };
  return (
    <>
      <div className="chatbot">
        <div className="chatbot-body-outer">
          {isFormVisible ? (
            <div className="chatbot-body">
              <div className="chatbot-header">
                <img src={logoDark} alt="" />
                <p>Question? Chat with us!</p>
                <span>Support is online</span>
              </div>
              <div className="chatbot-text">
                <SimpleBar style={{ maxHeight: 290, color: "white" }}>
                  <div className="chatbot-body-inner">
                    <div className="chatbot-msg">
                      <div className="chatbot-expert-msg">
                        <img src={logoLight} alt="" />
                        <div className="chatbot-hello-msg">
                          <span>Tax-link</span>
                          <p>Hello! How can i help you?</p>
                        </div>
                      </div>
                      <div className="chatbot-msg-date">
                        <p>20 Feb</p>
                      </div>
                      <div className="chatbot-user-msg">
                        <p>Hi</p>
                        <p>Good morning</p>
                        <p>Good morning</p>
                        <p>Good morning</p>
                        <p>Good morning</p>
                      </div>
                    </div>
                  </div>
                </SimpleBar>
                <div className="msg-input">
                  <input
                    className="chatbot-input"
                    type="text"
                    name=""
                    id=""
                    placeholder="Compose your msg..."
                  />
                  <button className="chatbot-input-btn" type="submit">
                    <CaretRightOutlined className="input-btn-icon" />
                  </button>
                </div>
              </div>
            </div>
          ) : null}
        </div>
        <div
          className={`chatbot-icon ${isFormVisible ? "active" : ""}`}
          onClick={toggleChatbot}
        >
          {
            isFormVisible ? (
              <img
                src={chaticonclose}
                className="chetbot-img icon-close"
                alt=""
              />
            ) : (
              // <CloseCircleOutlined />
              <img src={chaticon} className="chetbot-img" alt="" />
            )
            // <CheckCircleOutlined />
          }
        </div>
      </div>
    </>
  );
};

export default ChatButton;
