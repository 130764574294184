import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { DeleteOutlined } from "@ant-design/icons";
import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-alpine.css";
import { Button } from "antd";
import { withProfile } from "../../contexts/profileContext";
import { withRole } from "../../contexts/roleContext";
import Spinner from "../../components/Common/Spinner/Spinner";
import { AgGridReact } from "ag-grid-react";
import withRouter from "../../components/Common/withRouter";

const TableAll = ({ validatedProfile, role, urlPathName, mainTableData, fetchMainTableData, isLoading, setIsLoading }) => {
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [users, setUsers] = useState();
  const [colDefs, setColDefs] = useState([]);

  const navigate = useNavigate();
  const { pathnameWithSlash: currentPath, pathWithoutSlash, pathOnlyName } = urlPathName;

  const masterPaths = ["/autosell", "/master", "/ledgermaster", "/item", "/ruelist", "/leadgergroupnames", "/ledgernames", "/stockgroup", "/stockitem", "/unit", "/vouchertype"];
  const deleteButtonInActionColumn = ["banking", "sales/excel", "item/excel", "ledgerbulk", "autosale"];

  useEffect(() => {
    fetchTableData();
  }, [mainTableData, validatedProfile?.companyId]);

  const fetchTableData = async () => {
    setIsLoading(true);
    try {
      if (mainTableData) {
        const processedData = processDatas(mainTableData);
        console.log("processedData", processedData);
        setColDefs(generateColumns(processedData));
        setUsers(processedData);
        setIsLoading(false);
      }
    } catch (error) {
      console.error("Error fetching table data:", error);
    }
  };

  const processDatas = (data) => {
    return data[pathWithoutSlash]?.map((obj) =>
      Object.entries(obj).reduce((acc, [key, value]) => {
        acc[key.replace(/_/g, " ")] = value;
        return acc;
      }, {})
    );
  };

  const generateColumns = (data) => {
    if (!data || !data.length) return [];

    const isBankingOrSales = deleteButtonInActionColumn.includes(pathWithoutSlash);
    const indexColumns = [
      isBankingOrSales && {
        headerName: "Action",
        field: "delete",
        suppressHeaderMenuButton: true,
        width: 50,
        cellStyle: { textAlign: "center" },
        cellRenderer: (params) => {
          // console.log("params", params.data.status);
          const isDeletable = role && ["D", "F"].includes(role[currentPath]);
          const buttonClass = isDeletable && params.data.status !== "Complete" ? "text-danger maintable-deleterow Table-deleterow" : "text-secondary maintable-deleterow Table-deleterow";

          return (
            <Button
              className={buttonClass}
              type="danger"
              disabled={!isDeletable || params.data.status === "Complete"}
              onClick={(event) => {
                event.stopPropagation();
                if (isDeletable) deleteSingleRow(params.data);
              }}
            >
              <DeleteOutlined />
            </Button>
          );
        },
      },
      {
        headerName: "Sr No",
        field: "Sr No",
        width: 100,
        cellClass: "justify-center-cell",
        headerClass: "justify-center-header",
        suppressHeaderMenuButton: true,
        headerCheckboxSelection: true,
        checkboxSelection: true,
        valueGetter: (params) => params.node.rowIndex + 1,
      },
    ].filter(Boolean);

    const fields = Object.keys(data[0]);
    const removeColumns = getRemoveColumns(pathWithoutSlash);
    const columns = fields
      .filter((field) => !removeColumns.includes(field.toLowerCase()) && field !== "status")
      .map((field) => ({
        field: field.replace(/_/g, " "),
        filter: false,
        flex: getFlexValue(field, pathWithoutSlash),
      }));

    const statusColumn = {
      headerName: "Status",
      field: "status",
      width: 120,
      cellRenderer: (params) => <span className={params.value}>{params.value}</span>,
      cellStyle: { textAlign: "center" },
    };

    return [...indexColumns, ...columns, statusColumn];
  };

  const getRemoveColumns = (path) => {
    const commonColumns = ["srno", "flag", "company master id", "user master id", "created by", "created on", "modified by", "modified on"];
    switch (path) {
      case "banking":
      case "sales/excel":
      case "ledgerbulk":
      case "autosale":
        return commonColumns;
      case "item":
        return [...commonColumns, "autosalerate", "baseunits", "cess tax", "count", "date", "gst applicable", "gstdetails", "hsnsac", "id", "integrated tax", "openingbalance", "parent", "quantity", "rate", "resercvedname", "setalter gst details", "taxability", "type of supply", "under"];
      case "ledgermaster":
        return [
          ...commonColumns,
          "address value",
          "applicable date",
          "bill by bill",
          "cess tax",
          "countryname value",
          "credit period",
          "gst applicable",
          "id",
          "inventory affected",
          "ledstatename value",
          "mailing name",
          "pan",
          "parent value",
          "partygstin",
          "paymentdetails list",
          "pincode",
          "registration type",
          "reservedname",
          "setalter gst details",
          "state",
          "taxability",
          "type of ledger",
          "vatdealertype value",
        ];
      default:
        return [];
    }
  };

  const getFlexValue = (field, path) => {
    const flexMap = {
      banking: { name: 2, "bank name": 1.7, "statement date": 1.7, "synced date": 1.7, pending: 0.8, saved: 0.8, total: 0.8, synced: 0.8, suggestion: 0.8, status: 0.8 },
      "sales/excel": { name: 3, "statement date": 1.7, "synced date": 1.7, pending: 1, saved: 1, total: 1, synced: 1, suggestion: 1, status: 1 },
      ledgerbulk: { name: 3, pending: 1, saved: 1, total: 1, synced: 1, suggestion: 1, status: 1 },
      autosale: { "name of autosale": 3, "sr no": 2, "total items": 2 },
      ledgermaster: { name: 3, "sr no": 2, "total items": 2 },
    };
    return flexMap[path]?.[field.toLowerCase()] || 1;
  };

  const deleteSingleRow = async (rowData) => {
    let deleteFile = window.confirm(`Are you want to delete "${rowData.name}" file?`);
    if (deleteFile) {
      const deleteID = masterPaths.includes(currentPath) ? rowData.id : rowData.srno;
      try {
        const { status } = await axios.post(`${process.env.REACT_APP_API_URL}/deleteData`, {
          userId: validatedProfile.userType === "S" ? validatedProfile.parentid : validatedProfile.userid,
          companyId: validatedProfile.companyId,
          tablename: pathOnlyName === "item" ? "stockitem" : pathOnlyName,
          deleteID: [deleteID],
        });
        if (status === 200) {
          fetchMainTableData();
          fetchTableData();
        }
      } catch (error) {
        console.error("Error deleting row:", error);
      }
    }
  };

  const deleteSelectedRows = async () => {
    let deleteFile = window.confirm(`Are you want to delete all selected file?`);
    if (deleteFile) {
      try {
        const { status } = await axios.post(`${process.env.REACT_APP_API_URL}/deleteData`, {
          userId: validatedProfile.userType === "S" ? validatedProfile.parentid : validatedProfile.userid,
          companyId: validatedProfile.companyId,
          tablename: pathOnlyName,
          deleteID: selectedRowKeys,
        });
        if (status === 200) {
          fetchMainTableData();
          fetchTableData();
        }
      } catch (error) {
        console.error("Error deleting selected rows:", error);
      }
    }
  };

  const renderDeleteButton = () =>
    selectedRowKeys.length >= 2 &&
    role &&
    ["D", "F"].includes(role[currentPath]) && (
      <Button type="primary" className="deleteSelectedRow" onClick={deleteSelectedRows}>
        Delete Selected Row
      </Button>
    );

  const onSelectionChanged = (params) => {
    const selectedIds = params.api.getSelectedRows().map((row) => (masterPaths.includes(currentPath) ? row.id : row.srno));
    setSelectedRowKeys(selectedIds);
  };

  return (
    <>
      <Spinner isSpinner={isLoading} />
      <div className="ag-theme-alpine ag-main-table">
        <AgGridReact
          className="main_table_ag"
          rowData={users}
          columnDefs={colDefs}
          pagination
          rowSelection="multiple"
          paginationPageSize={20}
          paginationPageSizeSelector={[10, 20, 50, 100]}
          gridOptions={generateColumns}
          onCellClicked={(params) => {
            if (params.colDef.field !== "delete" && !masterPaths.includes(currentPath)) {
              navigate("/transactionstable", {
                state: {
                  key: params.data.srno,
                  pathWithoutSlash,
                  currentPath,
                  pathOnlyName,
                },
              });
            }
          }}
          onSelectionChanged={onSelectionChanged}
        />
        {renderDeleteButton()}
      </div>
    </>
  );
};

export default withProfile(withRole(withRouter(TableAll)));
