import React from "react";
import { useLocation, useNavigate } from "react-router-dom";

import { Button, DatePicker, Form, Input, Modal, Select } from "antd";
import axios from "axios";
import { withProfile } from "../../../../contexts/profileContext";

const QuickcreateModel = ({ validatedProfile, name, opencomponent, modal2Open, handleOkClick, handleCancel }) => {
  // console.log("name", name);
  const navigate = useNavigate();
  const location = useLocation();
  const currentPath = location.pathname;
  const pathWithoutSlash = currentPath.slice(1);
  const pathWithonlyname = pathWithoutSlash.split("/")[0];

  const handleLedgerSubmit = async (values) => {
    try {
      const data = {
        userId: validatedProfile.userType === "S" ? validatedProfile.parentid : validatedProfile.userid,
        companyId: validatedProfile.companyId,
      };
      if (pathWithonlyname === "item") {
        data["stockitem"] = [values];
      } else if (pathWithonlyname === "ledgermaster") {
        data["ledgermaster"] = [values];
      } else {
        data[pathWithonlyname] = [{ NAME: values.name }];
        data[pathWithonlyname + "data"] = [values];
      }

      // console.log("data", data);
      const response = await axios.post(`${process.env.REACT_APP_API_URL}/insertfileapi`, data, {
        headers: {
          "Content-Type": "application/json",
        },
      });

      // console.log("response", response.request.status);
      if (response.request.status === 200) {
        // console.log("datasss", response.data);
        opencomponent();
        // navigate(pathWithoutSlash);
      }
    } catch (error) {
      console.error("Error in uploading data", error);
      alert(error.response?.data?.message || "Error in uploading data");
    }
  };

  //   const handleItemSubmit = async (values) => {
  //     try {
  //       const data = {
  //         userId: validatedProfile.userType === "S" ? validatedProfile.parentid : validatedProfile.userid,
  //         companyId: validatedProfile.companyId,
  //       };
  //       data[pathWithonlyname] = [{ NAME: values.name }];
  //       data[pathWithonlyname + "data"] = values;

  //       //   console.log("data", data);

  //       const response = await axios.post(`${process.env.REACT_APP_API_URL}/insertfileapi`, data, {
  //         headers: {
  //           "Content-Type": "application/json",
  //         },
  //       });

  //       if (response.request.status === 200) {
  //         navigate("/item/excel");
  //         // console.log("response cc", response.request.status);
  //       }
  //     } catch (error) {
  //       console.error("Error in uploading data", error);
  //       alert(error.response?.data?.message || "Error in uploading data");
  //     }
  //   };
  // console.log("prop",prop);
  return (
    <>
      <Modal title={`Upload ${name}`} centered open={modal2Open} onOk={handleOkClick} onCancel={handleCancel} className="autosaleModel">
        {name === "ledgermaster" ? (
          <Form name="singleUploadForm" onFinish={handleLedgerSubmit} initialValues={{ remember: true }} style={{ maxHeight: "550px", overflow: "auto" }}>
            <Form.Item label="Name" name="name" rules={[{ required: true, message: "Please input the name!" }]}>
              <Input />
            </Form.Item>
            <Form.Item label="Under" name="under" rules={[{ required: true, message: "Please input the under category!" }]}>
              <Select>
                <Select.Option value="Bank Accounts">Bank Accounts</Select.Option>
                <Select.Option value="Bank OD A/c">Bank OD A/c</Select.Option>
                <Select.Option value="Branch / Divisions">Branch / Divisions</Select.Option>
                <Select.Option value="Capital Account">Capital Account</Select.Option>
                <Select.Option value="Cash-in-Hand">Cash-in-Hand</Select.Option>
                <Select.Option value="Current Assets">Current Assets</Select.Option>
                <Select.Option value="Current Liabilities">Current Liabilities</Select.Option>
                <Select.Option value="Deposits (Asset)">Deposits (Asset)</Select.Option>
                <Select.Option value="Direct Expenses">Direct Expenses</Select.Option>
                <Select.Option value="Direct Incomes">Direct Incomes</Select.Option>
                <Select.Option value="Duties & Taxes">Duties & Taxes</Select.Option>
                <Select.Option value="Fixed Assets">Fixed Assets</Select.Option>
                <Select.Option value="Indirect Expenses">Indirect Expenses</Select.Option>
                <Select.Option value="Indirect Incomes">Indirect Incomes</Select.Option>
                <Select.Option value="Investments">Investments</Select.Option>
                <Select.Option value="Loans & Advances (Asset)">Loans & Advances (Asset)</Select.Option>
                <Select.Option value="Loans (Liability)">Loans (Liability)</Select.Option>
                <Select.Option value="Misc. Expenses (ASSET)">Misc. Expenses (ASSET)</Select.Option>
                <Select.Option value="Provisions">Provisions</Select.Option>
                <Select.Option value="Purchase Accounts">Purchase Accounts</Select.Option>
                <Select.Option value="Reserves & Surplus">Reserves & Surplus</Select.Option>
                <Select.Option value="Sales Accounts">Sales Accounts</Select.Option>
                <Select.Option value="Secured Loans">Secured Loans</Select.Option>
                <Select.Option value="Stock-in-Hand">Stock-in-Hand</Select.Option>
                <Select.Option value="Sundry Creditors">Sundry Creditors</Select.Option>
                <Select.Option value="Sundry Debtors">Sundry Debtors</Select.Option>
                <Select.Option value="Suspense A/c">Suspense A/c</Select.Option>
                <Select.Option value="Unsecured Loans">Unsecured Loans</Select.Option>
              </Select>
            </Form.Item>

            <Form.Item label="mailing_name" name="mailing_name" rules={[{ required: true, message: "Please input the mailing name!" }]}>
              <Input />
            </Form.Item>
            <Form.Item label="Bill by bill" name="bill_by_bill" rules={[{ required: true, message: "Please input the bill by bill!" }]}>
              <Select>
                <Select.Option value="yes">Yes</Select.Option>
                <Select.Option value="no">No</Select.Option>
              </Select>
            </Form.Item>

            <Form.Item label="Registration type" name="registration_type" rules={[{ required: true, message: "Please input the registration type!" }]}>
              <Select>
                <Select.Option value="UNKNOWN">UNKNOWN</Select.Option>
                <Select.Option value="COMPOSITION">COMPOSITION</Select.Option>
                <Select.Option value="CONSUMER">CONSUMER</Select.Option>
                <Select.Option value="REGULAR">REGULAR</Select.Option>
                <Select.Option value="UNREGISTERED">UNREGISTERED</Select.Option>
              </Select>
            </Form.Item>

            <Form.Item label="Type of ledger" name="type_of_ledger" rules={[{ required: true, message: "Please select the type of ledger!" }]}>
              <Select>
                <Select.Option value="NOT APPLICABLE">NOT APPLICABLE</Select.Option>
                <Select.Option value="DISCOUNT">DISCOUNT</Select.Option>
                <Select.Option value="INVOICE ROUNDING">INVOICE ROUNDING</Select.Option>
              </Select>
            </Form.Item>

            <Form.Item label="Inventory affected" name="inventory_affected" rules={[{ required: true, message: "Please input the inventory affected!" }]}>
              <Select>
                <Select.Option value="yes">Yes</Select.Option>
                <Select.Option value="no">No</Select.Option>
              </Select>
            </Form.Item>
            <Form.Item label="Credit period" name="credit_period" rules={[{ required: true, message: "Please input the credit period!" }]}>
              <Input />
            </Form.Item>
            <Form.Item label="GST applicable" name="GST_applicable" rules={[{ required: true, message: "Please input the GST applicable!" }]}>
              <Select>
                <Select.Option value="applicable">Applicable</Select.Option>
                <Select.Option value="notApplicable">Not Applicable</Select.Option>
                <Select.Option value="Undefined">Undefined</Select.Option>
              </Select>
            </Form.Item>

            <Form.Item label="Set/Alter GST details" name="setalter_gst_details" rules={[{ required: true, message: "Please input the GST details!" }]}>
              <Select>
                <Select.Option value="Yes">Yes</Select.Option>
                <Select.Option value="No">No</Select.Option>
              </Select>
            </Form.Item>

            <Form.Item label="Taxability" name="taxability" rules={[{ required: true, message: "Please input the taxability!" }]}>
              <Select>
                <Select.Option value="Taxable">Taxable</Select.Option>
                <Select.Option value="Exempt">Exempt</Select.Option>
                <Select.Option value="Nil Rated">Nil Rated</Select.Option>
                <Select.Option value="Unknown">Unknown</Select.Option>
              </Select>
            </Form.Item>

            <Form.Item label="Integrated tax" name="integrated_tax" rules={[{ required: true, message: "Please input the integrated tax!" }]}>
              <Input />
            </Form.Item>
            <Form.Item label="Cess tax" name="cess_tax" rules={[{ required: true, message: "Please input the cess tax!" }]}>
              <Input />
            </Form.Item>
            <Form.Item label="Applicable date" name="applicable_date" rules={[{ required: true, message: "Please input the applicable date!" }]}>
              <DatePicker />
            </Form.Item>
            <Form.Item label="Address" name="address" rules={[{ required: true, message: "Please input the address!" }]}>
              <Input />
            </Form.Item>
            <Form.Item label="State" name="state" rules={[{ required: true, message: "Please input the state!" }]}>
              <Input />
            </Form.Item>
            <Form.Item label="Pincode" name="pincode" rules={[{ required: true, message: "Please input the pincode!" }]}>
              <Input />
            </Form.Item>
            <Form.Item label="PAN/IT no." name="pan" rules={[{ required: true, message: "Please input the PAN/IT no.!" }]}>
              <Input />
            </Form.Item>
            <Form.Item label="GSTIN/UIN" name="gstinuin" rules={[{ required: true, message: "Please input the GSTIN/UIN!" }]}>
              <Input />
            </Form.Item>
            <Form.Item>
              <Button className="submitLedger" type="primary" htmlType="submit">
                Submit Ledger
              </Button>
            </Form.Item>
          </Form>
        ) : (
          <Form name="singleUploadForm" onFinish={handleLedgerSubmit} initialValues={{ remember: true }} style={{ maxHeight: "550px", overflow: "auto" }}>
            <Form.Item label="Name" name="name" rules={[{ required: true, message: "Please input the name!" }]}>
              <Input />
            </Form.Item>
            <Form.Item label="Description" name="description" rules={[{ required: true, message: "Please input the description!" }]}>
              <Input />
            </Form.Item>
            <Form.Item label="Under" name="under" rules={[{ required: true, message: "Please input the under category!" }]}>
              <Input />
            </Form.Item>
            <Form.Item label="Category" name="category" rules={[{ required: true, message: "Please input the category!" }]}>
              <Input />
            </Form.Item>
            <Form.Item label="Unit" name="unit" rules={[{ required: true, message: "Please input the unit!" }]}>
              <Input />
            </Form.Item>
            <Form.Item label="GST Applicable" name="gst_applicable" rules={[{ required: true, message: "Please input the unit!" }]}>
              <Input />
            </Form.Item>
            <Form.Item label="Type of Supply" name="type_of_supply" rules={[{ required: true, message: "Please input the type of supply!" }]}>
              <Input />
            </Form.Item>
            <Form.Item label="HSN/SAC" name="hsnsac" rules={[{ required: true, message: "Please input the HSN/SAC!" }]}>
              <Input />
            </Form.Item>
            <Form.Item label="Set/Alter GST Details" name="setalter_gst_details" rules={[{ required: true, message: "Please input the GST details!" }]}>
              <Input />
            </Form.Item>
            <Form.Item label="Taxability" name="taxability" rules={[{ required: true, message: "Please input the taxability!" }]}>
              <Select>
                <Select.Option value="Taxable">Taxable</Select.Option>
                <Select.Option value="Exempt">Exempt</Select.Option>
                <Select.Option value="Nil Rated">Nil Rated</Select.Option>
                <Select.Option value="Unknown">Unknown</Select.Option>
              </Select>
            </Form.Item>

            <Form.Item label="Integrated Tax" name="integrated_tax" rules={[{ required: true, message: "Please input the integrated tax!" }]}>
              <Select>
                <Select.Option value="5%">5%</Select.Option>
                <Select.Option value="12%">12%</Select.Option>
                <Select.Option value="18%">18%</Select.Option>
              </Select>
            </Form.Item>
            <Form.Item label="Cess Tax" name="cess_tax" rules={[{ required: true, message: "Please input the cess tax!" }]}>
              <Select>
                <Select.Option value="1%">1%</Select.Option>
                <Select.Option value="3%">3%</Select.Option>
              </Select>
            </Form.Item>

            <Form.Item label="Applicable Date" name="applicable_date" rules={[{ required: true, message: "Please input the applicable date!" }]}>
              <DatePicker />
            </Form.Item>
            <Form.Item label="Quantity" name="quantity" rules={[{ required: true, message: "Please input the quantity!" }]}>
              <Input />
            </Form.Item>
            <Form.Item label="Rate" name="rate" rules={[{ required: true, message: "Please input the rate!" }]}>
              <Input />
            </Form.Item>
            <Form.Item>
              <Button type="primary" htmlType="submit">
                Submit Item
              </Button>
            </Form.Item>
          </Form>
        )}
      </Modal>
    </>
  );
};

export default withProfile(QuickcreateModel);
