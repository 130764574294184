import React from 'react'

const Payment = () => {
    return (  
        <>
            <div className='page-content'>
                <div>Payment</div> 
            </div>
        </>
    )
}

export default Payment;