import { Button, Empty, Input, Modal, Select, Table } from "antd";
import React, { useState, useEffect } from "react";

const ErrorModel = ({ stockitemNameError, ledgerNameError, validatedProfile }) => {
  const [errorModalOpen, setErrorModalOpen] = useState(false);
  // console.log("errorModalOpen", stockitemNameError);
  // console.log("ledgerNameError", ledgerNameError);
  useEffect(() => {
    if ((stockitemNameError && stockitemNameError.length > 0) || (ledgerNameError && ledgerNameError.length > 0)) {
      setErrorModalOpen(true);
    } else {
      setErrorModalOpen(false);
    }
  }, [stockitemNameError, ledgerNameError]);

  return (
    <>
      <Modal className="errorMenu-model" open={errorModalOpen} onCancel={() => setErrorModalOpen(false)} footer={null}>
        <div className="errorMenu-outer">
          <div className="errorMenu-inner">
            <div className="errorMenu-header">
              <p className="errorMenu-model-header">Auto Ledger/Item Creation - Missing Ledgers/Items</p>
            </div>
            <div className="errorMenu-body">
              <div className="mismatched-line">
                <p className="errorMenu-body-mismatched-line"> {ledgerNameError.length} Ledgers (Party ac name) are mismatched.</p>
                <p className="errorMenu-body-mismatched-line">{stockitemNameError.length} Items (Name of item) are mismatched.</p>
              </div>
              <div className="note-line">
                <p className="errorMenu-body-note-line">Click on theicon to create them automatically.</p>
                <p className="errorMenu-body-note-line">You will find the "+" icon next to the Party A/c name & Item name columns.</p>
                <p className="errorMenu-body-note-line errorMenu-note">
                  <span>Note:</span> Names are case-sensitive, and even extra spaces can result in mismatched entries.
                </p>
              </div>
            </div>
          </div>
        </div>
      </Modal>
    </>
  );
};

export default ErrorModel;
