import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { useLocation } from "react-router-dom";
import withRouter from "../components/Common/withRouter";

import Sidebar from "./Sidebar/Sidebar";
import Header from "./Header/Header";
import SidebarSmall from "./Sidebar/SidebarSmall";
import HeaderSmall from "./Header/HeaderSmall";
import TopBar from "./TopBar/TopBar";
import ChatButton from "../constants/ChatButton";
import TransactionsTable from "./SubTable/TransactionsTable";
import Mapping from "./Mapping/Mapping";

function shouldRenderTopBar(currentPath) {
  return currentPath !== "/dashboard" && currentPath !== "/analysis" && currentPath !== "/reports" && currentPath !== "/gst-record" && currentPath !== "/document" && currentPath !== "/learn";
}

const Layout = (props) => {
  
  const location = useLocation();
  const currentPath = location.pathname;

  const [isButtonClicked, setIsButtonClicked] = useState(false);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const handleButtonClick = () => {
    setIsButtonClicked(!isButtonClicked);
  };

  return (
    <React.Fragment>
      <div id="layout-wrapper">
        {currentPath === "/transactionstable" || currentPath === "/mapping" ? (
          currentPath === "/transactionstable" ? (
            <>
              <TransactionsTable />
             
            </>
          ) : (
            <Mapping />
          )
        ) : isButtonClicked ? (
          <>
            <Header handleButtonClick={handleButtonClick} />
            <Sidebar />
            <div className="main-content">
              {shouldRenderTopBar(currentPath) && <TopBar />}
              {props.children}
            </div>
          </>
        ) : (
          <>
            <HeaderSmall handleButtonClick={handleButtonClick} />
            <SidebarSmall />
            <div className="main-content-small">
              {shouldRenderTopBar(currentPath) && <TopBar />}
              {props.children}
            </div>
          </>
        )}
        {currentPath !== "/transactionstable" && currentPath !== "/mapping" ? (
          <div className="chatbutton">
            <ChatButton />
          </div>
        ) : null}
      </div>
    </React.Fragment>
  );
};

Layout.propTypes = {
  children: PropTypes.node,
};

export default withRouter(Layout);
