import pdfToText from "react-pdftotext";
import React from "react";
import { wait } from "@testing-library/user-event/dist/utils";

import * as XLSX from "xlsx";
// import pdfToText from "react-pdftotext";

const pdfReader = async (files, selectedDataArrayBank) => {
  // pdfToText(files)
  let selectedBankName = selectedDataArrayBank;
  // console.log("files", files);
  // console.log("selectedDataArrayBank", selectedDataArrayBank);
  // console.log("text if", text);
  const pdfresponce = await pdfToText(files).then((text) => {
    // const sbipdf = file.name.search(/\bSBI\b(?:\s+\w+)?/);

    if (selectedBankName === "State Bank Of India") {
      // console.log("text if", text);

      const index = text.search(/Debit\s+Credit\s+Balance/);

      const sbicurrent = text.search(/\bBranch Code\b/);
      if (sbicurrent) {
        const regex = /Balance as on 21 May 2024\s*:\s*([\d,]+\.\d{2})/;

        const match = text.match(regex);
        if (match) {
          var innitialbalance = parseFloat(match[1].replace(/,/g, ""));
        }
      }

      text = text.substring(index).trim();
      text = text.replace(/Closing\s+Balance.*/i, "").trim();
      // console.log("text", text);

      const pattern = sbicurrent !== -1 ? /\s(?=\d{2}\/\d{2}\/\d{4}|\d{2}\/\d{2}\/\d{4})/ : /\s(?=\d{2}-\d{2}-\d{4}|\d{1,2} [A-Za-z]{3} \d{4})/; //open

      const transactionsContent = text.trim().split(pattern);
      // console.log("transactionsContent", transactionsContent);

      const transactions = [];

      for (let i = 0; i < transactionsContent.length; i++) {
        const transactionContent = transactionsContent[i].trim();

        if (transactionContent === "Debit   Credit   Balance ") {
          continue;
        }

        const regex =
          sbicurrent !== -1
            ? /^(\d{2}\/\d{2}\/\d{4})\s+(.*?)\s+([\d,]+\.\d{2})\s+([\d,]+\.\d{2})$/gm //currunt new file
            : /^(\d{1,2}\s\w{3}\s\d{4})\s+(.+?)\s+([\d,]+\.\d{2})\s+([\d,]+\.\d{2})$/gm; //saving old file

        let match;
        while ((match = regex.exec(transactionContent)) !== null) {
          const transaction = {
            date: match[1],
            description: match[2].trim(),
            debit: parseFloat(match[3].replace(/,/g, "")) || 0,
            balance: parseFloat(match[4].replace(/,/g, "")) || 0,
            credit: 0,
          };

          transactions.push(transaction);
        }
      }

      for (let i = 1; i < transactions.length; i++) {
        if (innitialbalance && i === 1) {
          if (innitialbalance - transactions[0].debit !== transactions[0].balance) {
            transactions[0].credit = transactions[0].debit;
            transactions[0].debit = 0;
          }
        }
        if (transactions[i - 1].balance - transactions[i].debit === transactions[i].balance) {
          transactions[i].credit = 0;
        } else {
          transactions[i].credit = transactions[i].debit;
          transactions[i].debit = 0;
        }
      }
      // console.log("transactions new", transactions);
      return transactions;
      // if (transactions.length > 0) {
      //   transactions.shift();
      // }
    }
    if (selectedBankName === "Bank Of Baroda") {
      // console.log("text", text);

      const index = text.search(/BALANCE\s+\d{1,2}-\d{1,2}-\d{4}\s+Opening\s+Balance\s*/i);

      text = text.substring(index).trim();
      text = text.replace(/Closing\s+Balance.*/i, "").trim();

      const pattern = /\s(?=\d{2}-\d{2}-\d{4})/;

      const transactionsContent = text.trim().split(pattern);
      // console.log("transactionsContent", transactionsContent);

      const transactions = [];

      for (let i = 0; i < transactionsContent.length; i++) {
        const transactionContent = transactionsContent[i].trim().split(/Page\s+/i)[0];
        const transactionParts = transactionContent.trim().split(/\s+/);
        // console.log("transactionParts", transactionParts);

        if (transactionParts.length < 5) {
          continue;
        }

        const transaction = {
          date: transactionParts[0],
          description: transactionParts.slice(1, transactionParts.length - 3).join(" ") || null,
          balance: parseFloat(transactionParts[transactionParts.length - 2].replace(/,/g, "")) || 0,
          debit: parseFloat(transactionParts[transactionParts.length - 3].replace(/,/g, "")) || 0,
          // credit: parseFloat(transactionParts[transactionParts.length - 3].replace(/,/g, "")) || 0,
        };
        transactions.push(transaction);
      }
      for (let i = 1; i < transactions.length; i++) {
        if (transactions[i - 1].balance - transactions[i].debit === transactions[i].balance) {
          transactions[i].credit = 0;
        } else {
          transactions[i].credit = transactions[i].debit;
          transactions[i].debit = 0;
        }
      }

      if (transactions.length > 0) {
        transactions.shift();
      }

      // console.log("transactions", transactions);
      return transactions;
    }
    if (selectedBankName === "SBM NIYO GLOBAL") {
      // console.log("text", text);

      const index = text.search(/BALANCE\s+\d{1,2}-\d{1,2}-\d{4}\s+Opening\s+Balance\s*/i);

      // console.log("transactionsContent", transactionsContent);
      text = text.substring(index).trim();

      const pattern = /\s(?=\d{2}-\d{2}-\d{4})/;

      const transactionsContent = text.trim().split(pattern);

      const transactions = [];
      // console.log("transactionsContent", transactionsContent);

      for (let i = 0; i < transactionsContent.length; i++) {
        let parts = transactionsContent[i].trim().split(/(\.\d{1,2})/);
        parts = parts[0] + parts[1] || "";
        // console.log("parts", parts);
        const transactionParts = parts.trim().split(/\s+/);
        // console.log("transactionParts", transactionParts);

        if (transactionParts.length < 5) {
          continue;
        }

        const transaction = {
          date: transactionParts[0].split("-").reverse().join("-"),
          description: transactionParts.slice(1, transactionParts.length - 2).join(" "),
          balance: parseFloat(transactionParts[transactionParts.length - 1].replace(/,/g, "")),
          debit: Math.abs(parseFloat(transactionParts[transactionParts.length - 2].replace(/,/g, ""))) || Math.abs(parseFloat(transactionParts[transactionParts.length - 3].replace(/,/g, ""))),
          // credit: parseFloat(transactionParts[transactionParts.length - 3].replace(/,/g, "")) || 0,
        };
        transactions.push(transaction);
      }
      for (let i = 1; i < transactions.length; i++) {
        if (transactions[i - 1].balance - transactions[i].debit === transactions[i].balance) {
          transactions[i].credit = 0;
        } else {
          transactions[i].credit = transactions[i].debit;
          transactions[i].debit = 0;
        }
      }

      if (transactions.length > 0) {
        transactions.shift();
      }

      // console.log("transactions", transactions);
      return transactions;
      // uploadToDatabase(files[0].name, transactions);
    }
    if (selectedBankName === "ICICI Credit Card") {
      // console.log("text", text);

      const index = text.search(/BALANCE\s+\d{1,2}-\d{1,2}-\d{4}\s+Opening\s+Balance\s*/i);

      // console.log("transactionsContent", transactionsContent);
      text = text.substring(index).trim();

      const pattern = /\s(?=\d{2}-\d{2}-\d{4})/;

      const transactionsContent = text.trim().split(pattern);

      const transactions = [];
      // console.log("transactionsContent", transactionsContent);

      for (let i = 1; i < transactionsContent.length; i++) {
        let parts = transactionsContent[i].trim().split(/(CR|DR)\s+/i);
        // let parts = transactionsContent[i].trim().split(/(?:CR||DR)\s+/i) ;

        parts = parts[0] + (parts[1] || "");
        // console.log("parts", parts);
        const transactionParts = parts.trim().split(/\s+/);
        // console.log("transactionParts", transactionParts);

        if (transactionParts.length < 5) {
          continue;
        }

        const transaction = {
          date: transactionParts[0].split("-").reverse().join("-"),
          description: transactionParts.slice(1, transactionParts.length - 2).join(" "),
          balance: "0",
          debit: transactionParts[transactionParts.length - 1] === "DR" ? transactionParts[transactionParts.length - 2] : 0,
          credit: transactionParts[transactionParts.length - 1] === "CR" ? transactionParts[transactionParts.length - 2] : 0,
        };
        transactions.push(transaction);
      }

      if (transactions.length > 0) {
        transactions.shift();
      }

      // console.log("transactions", transactions);
      return transactions;
    }
    if (selectedBankName === "DCB") {
      // console.log("text", text);

      const index = text.search(/BALANCE\s+\d{1,2}-\d{1,2}-\d{4}\s+Opening\s+Balance\s*/i);

      const regex = /Opening Balance\s+([\d,]+\.\d+)/;
      const match = text.match(regex);
      if (match) {
        var innitialbalance = parseFloat(match[1].replace(/,/g, ""));
      }
      // console.log("innitialbalance", innitialbalance);
      text = text.replace(/.*Opening Balance\s+\d+(?:,\d{3})*(?:\.\d{2})?/, "");

      text = text.replace(/\sto\s/g, "to");
      // console.log("transactionsContent", transactionsContent);
      text = text.substring(index).trim();

      const patternss = /Closing Balance\s+\d+(?:,\d{3})*(?:\.\d+)?/;

      text = text.replace(new RegExp(`${patternss.source}.*`), "").trim();

      const pattern = /\s(?=\d{2}-\d{2}-\d{4})/;

      const transactionsContent = text.trim().split(pattern);

      const transactions = [];
      // console.log("transactionsContent", transactionsContent);

      for (let i = 0; i < transactionsContent.length; i++) {
        const transactionParts = transactionsContent[i].trim().split(/\s+/);
        // console.log("transactionParts", transactionParts);

        // if (transactionParts.length < 5) {
        //   continue;
        // }

        const transaction = {
          date: transactionParts[0],
          description: transactionParts.slice(1, transactionParts.length - 2).join(" "),
          balance: parseFloat(transactionParts[transactionParts.length - 1].replace(/,/g, "")),
          debit: parseFloat(transactionParts[transactionParts.length - 2].replace(/,/g, "")),
          // credit: transactionParts[transactionParts.length - 1] === "CR" ? transactionParts[transactionParts.length - 2] ,
        };

        transactions.push(transaction);
      }

      for (let i = 1; i < transactions.length; i++) {
        if (innitialbalance || (innitialbalance === 0 && i === 1)) {
          if (innitialbalance - transactions[0].debit !== transactions[0].balance) {
            transactions[0].credit = transactions[0].debit;
            transactions[0].debit = 0;
          }
        }
        if (transactions[i - 1].balance - transactions[i].debit === transactions[i].balance) {
          transactions[i].credit = 0;
        } else {
          transactions[i].credit = transactions[i].debit;
          transactions[i].debit = 0;
        }
      }

      // console.log("transactions", transactions);
      return transactions;
    }
    // if (selectedBankName === "HDFC Credit Card") {
    //   console.log("text true", text);

    //   // const index = text.search(/BALANCE\s+\d{1,2}-\d{1,2}-\d{4}\s+Opening\s+Balance\s*/i);

    //   // console.log("transactionsContent", transactionsContent);
    //   // text = text.substring(index).trim();

    //   const pattern = /\s(?=\d{2}\/\d{2}\/\d{2})/;

    //   // const parts = text.split(pattern);

    //   // const datePart = parts[1] ? parts[1].trim() : "";

    //   // console.log("Date Part:", datePart);

    //   const transactionsContent = text.trim().split(pattern);

    //   console.log("transactionsContent new", transactionsContent);
    //   const transactions = [];
    //   let transactionsContentnew = [];
    //   for (let i = 1; i < transactionsContent.length; i += 2) {
    //     transactionsContentnew.push(transactionsContent[i] + transactionsContent[i + 1]);

    //     // let parts = transactionsContent[i].trim().split(/(CR|DR)\s+/i);
    //     // // let parts = transactionsContent[i].trim().split(/(?:CR||DR)\s+/i) ;

    //     // parts = parts[0] + (parts[1] || "");
    //     // // console.log("parts", parts);
    //     // const transactionParts = parts.trim().split(/\s+/);
    //     // // console.log("transactionParts", transactionParts);

    //     // if (transactionParts.length < 5) {
    //     //   continue;
    //     // }

    //     // const transaction = {
    //     //   date: transactionParts[0].split("-").reverse().join("-"),
    //     //   description: transactionParts.slice(1, transactionParts.length - 2).join(" "),
    //     //   balance: "-",
    //     //   debit: transactionParts[transactionParts.length - 1] === "DR" ? transactionParts[transactionParts.length - 2] : 0,
    //     //   credit: transactionParts[transactionParts.length - 1] === "CR" ? transactionParts[transactionParts.length - 2] : 0,
    //     // };
    //     // transactions.push(transaction);
    //   }

    //   if (transactions.length > 0) {
    //     transactions.shift();
    //   }

    //   console.log("transactionsContentnew", transactionsContentnew);
    //   // console.log("transactions", transactions);
    // }

    if (selectedBankName === "AXIS SAVING") {
      // console.log("text", text);
      const regex = /OPENING BALANCE\s+([\d,]+\.\d+)/;
      const match = text.match(regex);
      if (match) {
        var innitialbalance = parseFloat(match[1].replace(/,/g, ""));
        // console.log("innitialbalance", innitialbalance);
      }
      text = text.replace(/.*OPENING BALANCE\s+\d+(?:,\d{3})*(?:\.\d{2})?/, "");
      text = text.replace(/\sto\s/g, "to");
      const patternss = /Closing Balance\s+\d+(?:,\d{3})*(?:\.\d+)?/;
      text = text.replace(new RegExp(`${patternss.source}.*`), "").trim();
      text = text.replace(/TRANSACTION TOTAL[\s\S]*/, "");
      const pattern = /\s(?=\d{2}-\d{2}-\d{4})/;
      const transactionsContent = text.trim().split(pattern);
      const transactions = [];
      // console.log("transactionsContent", transactionsContent);
      for (let i = 0; i < transactionsContent.length; i++) {
        const transactionParts = transactionsContent[i].trim().split(/\s+/);
        // if (transactionParts.length < 5) {
        //   continue;
        // }
        const transaction = {
          date: transactionParts[0],
          description: transactionParts.slice(1, transactionParts.length - 3).join(" "),
          balance: parseFloat(transactionParts[transactionParts.length - 2].replace(/,/g, "")),
          debit: parseFloat(transactionParts[transactionParts.length - 3].replace(/,/g, "")),
          // credit: transactionParts[transactionParts.length - 1] === "CR" ? transactionParts[transactionParts.length - 2] ,
        };
        transactions.push(transaction);
      }
      // console.log("transactions old", transactions);
      function truncateToTwoDecimals(value) {
        const factor = Math.pow(10, 2);
        return Math.floor(value * factor) / factor;
      }
      for (let i = 1; i < transactions.length; i++) {
        if (innitialbalance && i === 1) {
          if (innitialbalance - transactions[0].debit !== transactions[0].balance) {
            transactions[0].credit = transactions[0].debit;
            transactions[0].debit = 0;
          }
        }
        if (truncateToTwoDecimals(transactions[i - 1].balance - (transactions[i].debit || transactions[i].credit)) === transactions[i].balance) {
          transactions[i].credit = 0;
        } else {
          transactions[i].credit = transactions[i].debit;
          transactions[i].debit = 0;
        }
      }
      //   console.log("transactions new", transactions);
      return transactions;
    }
    //-------------------------------------------------------------------------------------------------------------------------------------------------------

    if (selectedBankName === "AXIS CURRENT") {
      // console.log("text", text);
      const index = text.search(/BALANCE\s+\d{1,2}-\d{1,2}-\d{4}\s+Opening\s+Balance\s*/i);
      text = text.substring(index).trim();
      text = text.replace(/Closing\s+Balance.*/i, "").trim();
      // text = text.replace(/^(.*?)(\d{2}-\d{2}-\d{4}.*?\d{5}\.\d{2})\s+.*/, '$2');
      const pattern = /\s(?=\d{2}-\d{2}-\d{4})/;
      let transactionsContent = text.trim().split(pattern);
      // transactionsContent = transactionsContent.match(/^(.*?\d{5}\.\d{2})/)[1];
      // console.log("transactionsContent new", processedText);
      // console.log("transactionsContent old", transactionsContent);
      const transactions = [];
      for (let i = 0; i < transactionsContent.length; i++) {
        function removeTextAfterTwoDecimals(text) {
          const regex = /(CR|DR)\s+(\d+\.\d{2})/g;
          let match;
          while ((match = regex.exec(text)) !== null) {
            if (match) {
              const matchedText = match[0];
              const matchIndex = match.index;
              return text.slice(0, matchIndex + matchedText.length).trim();
            }
          }
          return text.trim();
        }
        const transactionContent = removeTextAfterTwoDecimals(transactionsContent[i]);
        const transactionParts = transactionContent.trim().split(/\s+/);
        // console.log("transactionParts", transactionParts);
        if (transactionParts.length < 5) {
          continue;
        }
        const transaction = {
          date: transactionParts[0],
          description: transactionParts.slice(1, transactionParts.length - 3).join(" "),
          balance: parseFloat(transactionParts[transactionParts.length - 1].replace(/,/g, "")) || 0,
          debit: parseFloat(transactionParts[transactionParts.length - 3].replace(/,/g, "")) || 0,
          credit: transactionParts[transactionParts.length - 2].replace(/,/g, ""),
        };
        transactions.push(transaction);
      }
      for (let i = 1; i < transactions.length; i++) {
        if (transactions[i].credit === "DR") {
          transactions[i].credit = 0;
        } else {
          transactions[i].credit = transactions[i].debit;
          transactions[i].debit = 0;
        }
      }
      if (transactions.length > 0) {
        transactions.shift();
        transactions.shift();
      }
      //   console.log("transactions", transactions);
      return transactions;
    }
    //   -------------------------------------------------------------------------------------------------------------------------------------------------------

    if (selectedBankName === "KOTAK BANK") {
      // console.log("text", text);
      const index = text.search(/OPENING\s*BALANCE/i);

      text = text.substring(index).trim();

      let transactionsContent = text.replace(/Closing\s+Balance.*/i, "").trim();

      transactionsContent = transactionsContent.trim().split(/\s(?=\d{2}\s\w{3},\s\d{4})/);
      const transactions = [];
      for (let i = 0; i < transactionsContent.length; i++) {
        const result = transactionsContent[i].split(/(\d+\.\d{2}.*?\d+\.\d{2})\s/);

        transactionsContent[i] = result[0] + result[1];
        const transactionParts = transactionsContent[i].trim().split(/\s+/);
        // console.log("transactionParts", transactionParts);

        const transaction = {
          date: transactionParts.slice(0, 3).join(" "),
          description: transactionParts.slice(3, transactionParts.length - 3).join(" "),

          balance: parseFloat(transactionParts[transactionParts.length - 1].replace(/,/g, "")) || 0,

          debit: parseFloat(transactionParts[transactionParts.length - 2].replace(/,/g, "")) || 0,
        };
        transactions.push(transaction);
      }
      // console.log("transactions old", transactions);
      for (let i = 1; i < transactions.length; i++) {
        if (transactions[i].debit < 0) {
          transactions[i].debit = transactions[i].debit;
          transactions[i].credit = 0;
          transactions[i].debit = Math.abs(transactions[i].debit);
        } else {
          transactions[i].credit = transactions[i].debit;
          transactions[i].debit = 0;
        }
      }
      if (transactions.length > 0) {
        transactions.shift();
      }
      //   console.log("transactions new", transactions);
      return transactions;
    }
    //-------------------------------------------------------------------------------------------------------------------------------------------------------

    if (selectedBankName === "IDFC BANK") {
      // console.log("text", text);

      const regex = /Opening\s+Balance\s+([\d,]+\.\d{2})/i;
      const match = text.match(regex);

      if (match) {
        var innitialbalance = parseFloat(match[1].replace(/,/g, ""));
        // console.log("innitialbalance", innitialbalance);
      }

      const index = text.search(/(?<=Opening\sBalance\s+\d{1,3}(?:,\d{1,3})*\.\d{2})\s/i);

      text = text.substring(index).trim();

      let transactionsContent = text.replace(/Closing\s+Balance.*/i, "").trim();

      const transactions = [];
      transactionsContent = transactionsContent.split(/\s(?=\d{2}-[A-Za-z]{3}-\d{4})/);
      for (let i = 0; i < transactionsContent.length; i++) {
        const regex = /((?:.*?\d{1,3}(?:,\d{3})*\.\d{2}.*?){2})\s/;

        const match = transactionsContent[i].match(regex);

        if (match) {
          const index = match[0].length;
          const part1 = transactionsContent[i].slice(0, index).trim();
          const part2 = transactionsContent[i].slice(index).trim();

          transactionsContent[i] = part1;
        }

        const transactionParts = transactionsContent[i].trim().split(/\s+/);
        if (transactionParts.length < 2) {
          continue;
        }

        // console.log("transactionParts", transactionParts);

        const transaction = {
          date: transactionParts[0],
          description: transactionParts.slice(1, transactionParts.length - 2).join(" "),

          balance: parseFloat(transactionParts[transactionParts.length - 1].replace(/,/g, "")) || 0,

          debit: parseFloat(transactionParts[transactionParts.length - 2].replace(/,/g, "")) || 0,
        };
        transactions.push(transaction);
      }

      for (let i = 1; i < transactions.length; i++) {
        if (innitialbalance && i === 1) {
          if (innitialbalance + transactions[0].debit !== transactions[0].balance) {
            transactions[0].credit = 0;
          }
        }

        if (transactions[i - 1].balance - transactions[i].debit == transactions[i].balance) {
          transactions[i].credit = 0;
          transactions[i].debit = transactions[i].debit;
        } else {
          transactions[i].credit = transactions[i].debit;
          transactions[i].debit = 0;
        }
      }
      //   console.log("transactions new", transactions);
      return transactions;
    }

    if (selectedBankName === "FEDRAL BANK") {
      // console.log("text", text);

      function findOpeningBalanceValue(text) {
        const openingBalanceMatch = text.match(/Opening Balance(?: on)? (.*?) ₹ (\d+(?:,\d{3})*|\d{3})/i);

        if (openingBalanceMatch) {
          const openingBalanceValue = openingBalanceMatch[2].replace(/,/g, "");
          return openingBalanceValue;
        }
      }
      let innitialbalance = parseFloat(findOpeningBalanceValue(text));
      // console.log("opningBalance new", innitialbalance);

      const match = text.match(/Balance Balance\s/);

      if (match) {
        const index = match.index + match[0].length;
        text = text.slice(index).trim();
      }

      let transactionsContent = text.replace(/Closing\s+Balance.*/i, "").trim();

      const transactions = [];
      transactionsContent = transactionsContent.split(/\s(?=\d{1,2}\s(?:Jan|Feb|Mar|Apr|May|Jun|Jul|Aug|Sep|Oct|Nov|Dec))/);
      // console.log("transactionsContent", transactionsContent);

      for (let i = 0; i < transactionsContent.length; i++) {
        const regex = /\s(?=\.\d{2}\b)/;

        transactionsContent[i] = transactionsContent[i].replace(regex, "");

        const match = transactionsContent[i].match(/((?:.*?\d{1,3}(?:,\d{3})*\.\d{2}.*?){2})\s/);

        if (match) {
          const index = match[0].length;
          const part1 = transactionsContent[i].slice(0, index).trim();

          transactionsContent[i] = part1;
        }

        const transactionParts = transactionsContent[i].trim().replace(/,/g, "").split(/\s+/);
        if (transactionParts.length < 2) {
          continue;
        }

        // console.log("transactionParts", transactionParts);

        const transaction = {
          date: transactionParts.slice(0, 2).join(" "),
          description: transactionParts.slice(2, transactionParts.length - 2).join(" "),

          balance: parseFloat(transactionParts[transactionParts.length - 1].replace(/,/g, "")) || 0,

          debit: parseFloat(transactionParts[transactionParts.length - 2].replace(/,/g, "")) || 0,
        };
        transactions.push(transaction);
      }

      for (let i = 1; i < transactions.length; i++) {
        if (innitialbalance && i === 1) {
          if (innitialbalance + transactions[0].debit === transactions[0].balance) {
            transactions[0].credit = transactions[0].debit;
            transactions[0].debit = 0;
          }
        }

        if (transactions[i - 1].balance - transactions[i].debit == transactions[i].balance) {
          transactions[i].credit = 0;
          transactions[i].debit = transactions[i].debit;
        } else {
          transactions[i].credit = transactions[i].debit;
          transactions[i].debit = 0;
        }
      }
      //   console.log("transactions new", transactions);
      return transactions;
    }
  });
  // .catch((error) =>{} console.error("Failed to extract text from pdf", error));
  // console.log("pdfresponce component", pdfresponce);
  return pdfresponce;
};

export default pdfReader;

//============================================================================================================
export const xlsxReader = async (files) => {
  if (!files || files.length === 0) {
    throw new Error("No files provided");
  }

  const file = files[0];

  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.onload = (e) => {
      try {
        const datajson = new Uint8Array(e.target.result);
        const readOpts = {
          cellText: false,
          cellDates: true,
        };
        const workbook = XLSX.read(datajson, { type: "array", ...readOpts });
        const sheetName = workbook.SheetNames[0];
        const worksheet = workbook.Sheets[sheetName];
        // console.log("worksheet", worksheet);
        let json = XLSX.utils.sheet_to_json(worksheet, { raw: false, dateNF: "DD-MM-YYYY" });

        json = json.map(row => {
          return Object.fromEntries(
            Object.entries(row).map(([key, value]) => [key.toLowerCase(), value])
          );
        });
        // console.log("json", json);
        resolve(json);
      } catch (error) {
        console.error("Error reading Excel file:", error);
        reject(error);
      }
    };
    reader.readAsArrayBuffer(file);
  });
};
