// import React, { useState } from "react";
// import { DownOutlined, UpOutlined } from "@ant-design/icons";

// const MyPlan = () => {
//   const [showPlan, setshowPlan] = useState(false);
//   const [showkey, setshowkey] = useState(0);

//   const showPlanbtn = (varl, key) => {
//     if (showkey === key) {
//       setshowPlan(!varl);
//       setshowkey(0);
//     } else {
//       setshowPlan(true);
//       setshowkey(key);
//     }
//   };

//   const toggleShowMore = (e) => {
//     e.stopPropagation();
//     const showmoreUl = e.currentTarget.parentElement.querySelector(".showmore-ul");
//     if (showmoreUl) {
//       showmoreUl.classList.toggle("show");
//     }

//   };
//   const [firstPlan, setFirstPlan] = useState(0);
//   const [firstPlanCount, setFirstPlanCount] = useState(1);
//   const [firstPlanVal, setFirstPlanVal] = useState(5000);

//   const [secondPlan, setSecondPlan] = useState(100);
//   const [secondPlanCount, setSecondPlanCount] = useState(1);
//   const [secondPlanVal, setSecondPlanVal] = useState(12000);

//   const [thirdPlan, setThirdPlan] = useState(500);
//   const [thirdPanCount, setThirdPanCount] = useState(1);
//   const [thirdPlannVal, setThirdPlannVal] = useState(25000);

//   const SUB = (val) => {
//     if (val >= 10 && val <= 90) {
//       if (val > 0) {
//         setFirstPlan(firstPlan - 10);
//         setFirstPlanCount(firstPlanCount - 1);
//         setFirstPlanVal(firstPlanVal - 780);
//       }
//     }
//     if (val >= 100 && val <= 490) {
//       if (val > 100) {
//         setSecondPlan(secondPlan - 10);
//         setSecondPlanCount(secondPlanCount - 1);
//         setSecondPlanVal(secondPlanVal - 300);
//       }
//     }
//     if (val >= 500 && val <= 5000) {
//       if (val > 500) {
//         setThirdPlan(thirdPlan - 10);
//         setThirdPanCount(thirdPanCount - 1);
//         setThirdPlannVal(thirdPlannVal - 500);
//       }
//     }
//   };

//   const ADD = (val) => {
//     if (val >= 0 && val <= 90) {
//       if (val < 90) {
//         setFirstPlan(firstPlan + 10);
//         setFirstPlanCount(firstPlanCount + 1);
//         setFirstPlanVal(firstPlanVal + 780);
//       }
//     }
//     if (val >= 100 && val <= 490) {
//       if (val < 490) {
//         setSecondPlan(secondPlan + 10);
//         setSecondPlanCount(secondPlanCount + 1);
//         setSecondPlanVal(secondPlanVal + 300);
//       }
//     }
//     if (val >= 500 && val <= 5000) {
//       if (val < 5000) {
//         setThirdPlan(thirdPlan + 10);
//         setThirdPanCount(thirdPanCount + 1);
//         setThirdPlannVal(thirdPlannVal + 500);
//       }
//     }
//   };

//   return (
//     <>
//       <div className="page-content">
//         <div className="my-plan">
//           <ul className="my-plan-ul">
//             <li className="my-plan-li">
//               <p onClick={() => showPlanbtn(false, 1)}>My Plan {showPlan && showkey === 1 ? <UpOutlined /> : <DownOutlined />}</p>
//               {showkey === 1 && showPlan ? (

//                 <ul className="myplan-sub-ul">
//                   <li className="myplan-sub-li">
//                     <div className="plan-one">
//                       <sup>superscript</sup>
//                       <div className="price">
//                         <p> {firstPlanVal}</p>
//                         <sub>/year, billed yearly</sub>
//                       </div>
//                       <div className="count">
//                         <p>Total Company : </p>
//                         <button type="button" id="btn" onClick={() => SUB(firstPlan)}>
//                           -
//                         </button>

//                         <small>{firstPlan}</small>
//                         <button type="button" id="btn" onClick={() => ADD(firstPlan)}>
//                           +
//                         </button>
//                       </div>
//                       <small>Your Payable Amount :</small>
//                       <small className="firstPlanval"> {firstPlanVal}</small>
//                       <p className="showmore-btn" onClick={toggleShowMore}>
//                         Show More
//                         <ul className="showmore-ul">
//                           <li>Users & 10 Companies</li>
//                           <li>Unlimited document process </li>
//                           <li>INR 1000 for additional User</li>
//                           <li>Free Support 12 x 6</li>
//                         </ul>
//                       </p>
//                     </div>
//                   </li>
//                   <li className="myplan-sub-li">
//                     <div className="plan-one">
//                       <sup>superscript</sup>
//                       <div className="price">
//                         <p> {secondPlanVal}</p>
//                         <sub>/year, billed yearly</sub>
//                       </div>
//                       <div className="count">
//                         <p>Total Company : </p>
//                         <button type="button" id="btn" onClick={() => SUB(secondPlan)}>
//                           -
//                         </button>

//                         <small>{secondPlan}</small>
//                         <button type="button" id="btn" onClick={() => ADD(secondPlan)}>
//                           +
//                         </button>
//                       </div>
//                       <small>Your Payable Amount :</small>
//                       <small className="firstPlanval"> {secondPlanVal}</small>
//                       <p className="showmore-btn" onClick={toggleShowMore}>
//                         Show More
//                         <ul className="showmore-ul">
//                           <li>Users & 10 Companies</li>
//                           <li>Unlimited document process </li>
//                           <li>INR 1000 for additional User</li>
//                           <li>Free Support 12 x 6</li>
//                         </ul>
//                       </p>
//                     </div>
//                   </li>
//                   <li className="myplan-sub-li">
//                     <div className="plan-one">
//                       <sup>superscript</sup>
//                       <div className="price">
//                         <p> {thirdPlannVal}</p>
//                         <sub>/year, billed yearly</sub>
//                       </div>
//                       <div className="count">
//                         <p>Total Company : </p>
//                         <button type="button" id="btn" onClick={() => SUB(thirdPlan)}>
//                           -
//                         </button>

//                         <small>{thirdPlan}</small>
//                         <button type="button" id="btn" onClick={() => ADD(thirdPlan)}>
//                           +
//                         </button>
//                       </div>
//                       <small>Your Payable Amount :</small>
//                       <small className="firstPlanval"> {thirdPlannVal}</small>
//                       <p className="showmore-btn" onClick={toggleShowMore}>
//                         Show More
//                         <ul className="showmore-ul">
//                           <li>Users & 10 Companies</li>
//                           <li>Unlimited document process </li>
//                           <li>INR 1000 for additional User</li>
//                           <li>Free Support 12 x 6</li>
//                         </ul>
//                       </p>
//                     </div>
//                   </li>
//                 </ul>
//               ) : (
//                 " "
//               )}
//             </li>
//             <li className="my-plan-li">
//               <p onClick={() => showPlanbtn(false, 2)}>View Payment History {showPlan && showkey === 2 ? <UpOutlined /> : <DownOutlined />}</p>
//               {showkey === 2 && showPlan ? <h1>Hello</h1> : " "}
//             </li>
//             <li className="my-plan-li">
//               <p onClick={() => showPlanbtn(false, 3)}>View Transaction History {showPlan && showkey === 3 ? <UpOutlined /> : <DownOutlined />}</p>
//               {showkey === 3 && showPlan ? <h1>Hello</h1> : " "}
//             </li>
//           </ul>
//         </div>
//       </div>
//     </>
//   );
// };

// export default MyPlan;

import React, { useState } from "react";
import { DownOutlined, UpOutlined } from "@ant-design/icons";

const MyPlan = () => {
  const toggleShowMore = (e) => {
    e.stopPropagation();
    const showmoreUl = e.currentTarget.parentElement.querySelector(".showmore-ul");
    if (showmoreUl) {
      showmoreUl.classList.toggle("show");
    }
  };
  const toggleShow = (e) => {
    e.stopPropagation();
    const showmoreUl = e.currentTarget.parentElement.querySelector(".show-component");
    if (showmoreUl) {
      showmoreUl.classList.toggle("show");
    }
  };
  const [firstPlan, setFirstPlan] = useState(0);
  const [firstPlanCount, setFirstPlanCount] = useState(1);
  const [firstPlanVal, setFirstPlanVal] = useState(5000);

  const [secondPlan, setSecondPlan] = useState(100);
  const [secondPlanCount, setSecondPlanCount] = useState(1);
  const [secondPlanVal, setSecondPlanVal] = useState(12000);

  const [thirdPlan, setThirdPlan] = useState(500);
  const [thirdPanCount, setThirdPanCount] = useState(1);
  const [thirdPlannVal, setThirdPlannVal] = useState(25000);

  const SUB = (val) => {
    if (val >= 10 && val <= 90) {
      if (val > 0) {
        setFirstPlan(firstPlan - 10);
        setFirstPlanCount(firstPlanCount - 1);
        setFirstPlanVal(firstPlanVal - 780);
      }
    }
    if (val >= 100 && val <= 490) {
      if (val > 100) {
        setSecondPlan(secondPlan - 10);
        setSecondPlanCount(secondPlanCount - 1);
        setSecondPlanVal(secondPlanVal - 300);
      }
    }
    if (val >= 500 && val <= 5000) {
      if (val > 500) {
        setThirdPlan(thirdPlan - 10);
        setThirdPanCount(thirdPanCount - 1);
        setThirdPlannVal(thirdPlannVal - 500);
      }
    }
  };

  const ADD = (val) => {
    if (val >= 0 && val <= 90) {
      if (val < 90) {
        setFirstPlan(firstPlan + 10);
        setFirstPlanCount(firstPlanCount + 1);
        setFirstPlanVal(firstPlanVal + 780);
      }
    }
    if (val >= 100 && val <= 490) {
      if (val < 490) {
        setSecondPlan(secondPlan + 10);
        setSecondPlanCount(secondPlanCount + 1);
        setSecondPlanVal(secondPlanVal + 300);
      }
    }
    if (val >= 500 && val <= 5000) {
      if (val < 5000) {
        setThirdPlan(thirdPlan + 10);
        setThirdPanCount(thirdPanCount + 1);
        setThirdPlannVal(thirdPlannVal + 500);
      }
    }
  };

  return (
    <>
      <div className="page-content">
        <div className="my-plan">
          <ul className="my-plan-ul">
            <li className="my-plan-li">
              <p onClick={toggleShow}>
                My Plan <DownOutlined />
              </p>
              <div className="show-component show">
                <ul className="myplan-sub-ul">
                  <li className="myplan-sub-li">
                    <div className="plan-one">
                      <sup>₹</sup>
                      <div className="price">
                        <p> {firstPlanVal}</p>
                        <sub>/year, billed yearly</sub>
                      </div>
                      <div className="count">
                        <p>Total Company : </p>
                        <button type="button" id="btn" onClick={() => SUB(firstPlan)}>
                          -
                        </button>

                        <small>{firstPlan}</small>
                        <button type="button" id="btn" onClick={() => ADD(firstPlan)}>
                          +
                        </button>
                      </div>
                      <small>Your Payable Amount :</small>
                      <small className="firstPlanval"> {firstPlanVal}</small>
                      <p className="showmore-btn" onClick={toggleShowMore}>
                        Show More
                        <ul className="showmore-ul">
                          <li>Users & 10 Companies</li>
                          <li>Unlimited document process </li>
                          <li>INR 1000 for additional User</li>
                          <li>Free Support 12 x 6</li>
                        </ul>
                      </p>
                    </div>
                  </li>
                  <li className="myplan-sub-li">
                    <div className="plan-one">
                      <sup>₹</sup>
                      <div className="price">
                        <p> {secondPlanVal}</p>
                        <sub>/year, billed yearly</sub>
                      </div>
                      <div className="count">
                        <p>Total Company : </p>
                        <button type="button" id="btn" onClick={() => SUB(secondPlan)}>
                          -
                        </button>

                        <small>{secondPlan}</small>
                        <button type="button" id="btn" onClick={() => ADD(secondPlan)}>
                          +
                        </button>
                      </div>
                      <small>Your Payable Amount :</small>
                      <small className="firstPlanval"> {secondPlanVal}</small>
                      <p className="showmore-btn" onClick={toggleShowMore}>
                        Show More
                        <ul className="showmore-ul">
                          <li>Users & 10 Companies</li>
                          <li>Unlimited document process </li>
                          <li>INR 1000 for additional User</li>
                          <li>Free Support 12 x 6</li>
                        </ul>
                      </p>
                    </div>
                  </li>
                  <li className="myplan-sub-li">
                    <div className="plan-one">
                      <sup>₹</sup>
                      <div className="price">
                        <p> {thirdPlannVal}</p>
                        <sub>/year, billed yearly</sub>
                      </div>
                      <div className="count">
                        <p>Total Company : </p>
                        <button type="button" id="btn" onClick={() => SUB(thirdPlan)}>
                          -
                        </button>

                        <small>{thirdPlan}</small>
                        <button type="button" id="btn" onClick={() => ADD(thirdPlan)}>
                          +
                        </button>
                      </div>
                      <small>Your Payable Amount :</small>
                      <small className="firstPlanval"> {thirdPlannVal}</small>
                      <p className="showmore-btn" onClick={toggleShowMore}>
                        Show More
                        <ul className="showmore-ul">
                          <li>Users & 10 Companies</li>
                          <li>Unlimited document process </li>
                          <li>INR 1000 for additional User</li>
                          <li>Free Support 12 x 6</li>
                        </ul>
                      </p>
                    </div>
                  </li>
                </ul>
              </div>
            </li>
            <li className="my-plan-li">
              <p className="show-btn" onClick={toggleShow}>
                View Payment History <DownOutlined />
              </p>
              <div className="show-component">
                <li>Users & 10 Companies</li>
                <li>Unlimited document process </li>
                <li>INR 1000 for additional User</li>
                <li>Free Support 12 x 6</li>
              </div>
            </li>
            <li className="my-plan-li">
              <p className="show-btn" onClick={toggleShow}>
                View Transaction History <DownOutlined />
              </p>
              <div className="show-component">
                <li>Users & 10 Companies</li>
                <li>Unlimited document process </li>
                <li>INR 1000 for additional User</li>
                <li>Free Support 12 x 6</li>
              </div>
            </li>
          </ul>
        </div>
      </div>
    </>
  );
};

export default MyPlan;
