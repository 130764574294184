import React from 'react'

const GstRecord = () => {
    return (
        <>
            <div className='page-content'>
                GstRecord
            </div>
        </>
    )
}

export default GstRecord;