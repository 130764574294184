import React from 'react'

const Journal = () => {
    return (
        <>
            <div className='page-content'>
                <div>Journal</div> 
            </div>
        </>
    )
}

export default Journal;