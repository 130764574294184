import React, { useState, useEffect } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import TopBar from "../../../Layout/TopBar/TopBar";
import TableAll from "../../../Layout/MainTable/Table";
import axios from "axios";

// import { AgGridReact } from "ag-grid-react";
import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-alpine.css";
import { withProfile } from "../../../contexts/profileContext";
import { AgGridReact } from "ag-grid-react";

const MyCompany = ({ validatedProfile }) => {
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const location = useLocation();
  const currentPath = location.pathname;
  const pathWithoutSlash = currentPath.slice(1);
  const Navigate = useNavigate();
  const [masterPathsToCheckMaster, setMasterPathsToCheckMaster] = useState(["/master", "/ledgermaster", "/item", "/ruelist", "/leadgergroupnames", "/ledgernames", "/stockgroup", "/stockitem", "/unit", "/vouchertype"]);

  useEffect(() => {
    viewAll();
  }, []);

  const viewAll = () => {
    axios
      .post(`${process.env.REACT_APP_API_URL}/company-data`, {
        userId: validatedProfile.userType === "S" ? validatedProfile.parentid : validatedProfile.userid,
      })
      .then((response) => {
        if (response.status === 200) {
          const datas = response.data.getCompanyDatas;
          if (datas) {
            const newData = datas.map((obj) => {
              const newObj = {};
              for (const key in obj) {
                if (Object.hasOwnProperty.call(obj, key)) {
                  const newKey = key.replace(/_/g, " ");
                  newObj[newKey] = obj[key];
                }
              }
              return newObj;
            });
            setuser(newData);
            setcolDefs(generateColumns(newData));
          }
        }
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  };

  // const opensubtable = (key) => {
  //   // console.log("onClick", key);
  //   // Navigate("/transactionstable")
  //   Navigate("/transactionstable", {
  //     state: { key, pathWithoutSlash },
  //   });
  // };

  const isCurrentPathInList = masterPathsToCheckMaster.includes(currentPath);
  // ================ table  start ==============
  const [users, setuser] = useState([]);
  const [colDefs, setcolDefs] = useState();

  function generateColumns(input) {
    if (!input || !input.length) {
      return [];
    }

    const columns = Object.keys(input[0]).map((fields) => ({ field: fields.replace(/_/g, " "), resizable: true, filter: true, minWidth: 200, maxWidth: 300 }));

    return columns;
  }

  const gridOptions = {
    sizeColumnsToFit: true,
  };

  // ================table end ==============
  return (
    <>
      <div className="page-content">
        <div className="ag-theme-alpine ag-main-table ag-myCompany-table">
          <AgGridReact
            rowData={users}
            columnDefs={colDefs}
            gridOptions={gridOptions}
            // onRowClicked={masterPathsToCheckMaster.includes(currentPath) ? undefined : (key) => opensubtable(key.data.srno)}
          />
        </div>
      </div>
    </>
  );
};

// export default MyCompany;
export default withProfile(MyCompany);
