import React from 'react'

const SalesReturn = () => {
    return (
        <>
            <div className='page-content'>
                <div>SalesReturn</div> 
            </div>
        </>
    )
}

export default SalesReturn;