import React, { createContext, useState } from "react";
import { Pages } from "../constants/pages";
const GeneralContext = createContext();

const GeneralProvider = ({ children }) => {
  const [page, setPage] = useState(Pages[0].name);

  const [modal2Open, setModal2Open] = useState(false);

  const [name, setname] = useState();
  const opencomponent = (path) => {
    setModal2Open(!modal2Open);
    setname(path);
  };

  return (
    <GeneralContext.Provider
      value={{
        page,
        setPage,
        opencomponent,
        modal2Open,
        setModal2Open,
        name,
        setname,
      }}
    >
      {children}
    </GeneralContext.Provider>
  );
};

const withGeneral = (Child) => (props) => <GeneralContext.Consumer>{(context) => <Child {...props} {...context} />}</GeneralContext.Consumer>;

export { GeneralContext, GeneralProvider, withGeneral };
