import React from 'react'

const Contra = () => {
    return (  
        <>
            <div className='page-content'>
            <div>Contra</div> 
            </div>
        </>
    )
}

export default Contra;