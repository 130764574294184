import React from 'react'

const Analysis = () => {
    return (  
        <>
            <div className='page-content'>
                <div>Analysis</div> 
            </div>
        </>
    )
}

export default Analysis