import React from 'react'
// import TableAll from '../../../../Layout/MainTable/Table';

const Ruelist = () => {
    return (
        <>
            <div className='page-content'>
                {/* <TableAll/> */}
            </div>
        </>
    )
}

export default Ruelist;