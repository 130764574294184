import React, { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import TopBar from "../../../../Layout/TopBar/TopBar";
import TableAll from "../../../../Layout/MainTable/Table";

const AddCompany = () => {
  return (
    <>
      <div className="page-content">
        <TableAll />
      </div>
    </>
  );
};

export default AddCompany;
