// import React, { useEffect, useState } from "react";
// import { Button } from "reactstrap";
// import { useFormik } from "formik";
// import * as Yup from "yup";
// import { useNavigate } from "react-router-dom";
// import axios from "axios";
// import { withProfile } from "../../../../contexts/profileContext";
// import { useRef } from "react";
// import { EditOutlined } from "@ant-design/icons";

// const MyProfileForm = ({ validatedProfile }) => {
//   const [isEmailDisabled, setIsEmailDisabled] = useState(true);
//   const [isMobileDisabled, setIsMobileDisabled] = useState(true);

//   const navigate = useNavigate();
//   const [defoultData, setDefoultData] = useState([]);
//   const fileInputRef = useRef(null);
//   useEffect(() => {
//     printdata();
//   }, []);
//   // console.log("validatedProfile", validatedProfile.userType);
//   const printdata = async () => {
//     try {
//       const token = "bearer" + " " + validatedProfile.token;

//       const response = await axios.get(`${process.env.REACT_APP_API_URL}/user-details`, {
//         headers: {
//           Authorization: token,
//         },
//       });
//       const data = response.data.userDetails;
//       // console.log("data", data);
//       setDefoultData(data);
//       if (data) {
//         formik.setValues({
//           firstName: data.user_name.split(" ")[0] || "",
//           lastName: data.user_name.split(" ")[1] || "",
//           email: data.user_email || "",
//           mobile: data.user_mobile || "",
//           address: data.user_address || "",
//           pinCode: data.pincode || "",
//           city: data.user_city || "",
//           state: data.user_state || "",
//           country: data.user_country || "",
//           parentName: data.user_company || "Default",
//           image: "",
//         });
//       }
//     } catch (error) {
//       console.log(error);
//     }
//   };

//   const validationSchema = Yup.object().shape({
//     firstName: Yup.string().required("First Name is required"),
//     lastName: Yup.string().required("Last Name is required"),
//     email: Yup.string().email("Invalid email").required("Email is required"),
//     mobile: Yup.string().required("Mobile is required"),
//     address: Yup.string().required("Address is required"),
//     pinCode: Yup.string().required("Pin Code is required"),
//     city: Yup.string().required("City is required"),
//     state: Yup.string().required("State is required"),
//     country: Yup.string().required("Country is required"),
//     parentName: Yup.string().required("Parent Name is required"),
//     // parentName: Yup.string().required("Image is required"),
//   });

//   const handleSubmit = async (values) => {
//     values.username = values.firstName.concat(" ", values.lastName);
//     delete values.firstName;
//     delete values.lastName;
//     let imageName = values.image.split("\\").pop();
//     values.image = imageName;

//     console.log("values", values);

//     try {
//       const response = await axios.post(`${process.env.REACT_APP_API_URL}/fill-user-details`, values, {
//         headers: {
//           "Content-Type": "application/json",
//         },
//       });
//       // console.log("response", response);
//       alert("Registration successful!");
//     } catch (error) {
//       alert(error.response.data.message);
//     }
//   };

//   const formik = useFormik({
//     initialValues: {
//       firstName: "",
//       lastName: "",
//       email: defoultData.user_email,
//       mobile: "",
//       address: "",
//       pinCode: "",
//       city: "",
//       state: "",
//       country: "",
//       parentName: "" || null,
//       image: "",
//     },
//     validationSchema: validationSchema,
//     onSubmit: handleSubmit,
//   });

//   return (
//     <>
//       <div className="page-content">
//         <div className="my_profile">
//           <div className="profile_heading">
//             <p className="profile_heading_p">Personal Information</p>
//             <Button onClick={() => navigate("/change-Password")}>Change password</Button>
//           </div>
//           <form className="my_profile_form" onSubmit={formik.handleSubmit}>
//             <div className="profile_content">
//               <div className="profile_body">
//                 <div className="name">
//                   <label htmlFor="firstName" className="my_profile_label">
//                     First Name
//                   </label>
//                   <input className="input_padding" type="text" id="firstName" {...formik.getFieldProps("firstName")} />

//                   {formik.touched.firstName && formik.errors.firstName ? <div className="error_div">{formik.errors.firstName}</div> : null}
//                 </div>
//                 <div className="name">
//                   <label htmlFor="lastName" className="my_profile_label">
//                     Last Name
//                   </label>
//                   <input className="input_padding" type="text" id="lastName" {...formik.getFieldProps("lastName")} />
//                   {formik.touched.lastName && formik.errors.lastName ? <div className="error_div">{formik.errors.lastName}</div> : null}
//                 </div>
//               </div>
//               <div className="profile_body">
//                 <div className="name ">
//                   <div className="email_mo_div">
//                     <label htmlFor="email" className="my_profile_label">
//                       Email
//                     </label>
//                     <span className="email_mo_update_btn" onClick={() => setIsEmailDisabled(false)}>
//                       <EditOutlined className="email_mo_update_btn_icon" />
//                       Change Email
//                     </span>
//                   </div>
//                   <input className="input_padding" type="text" id="email" {...formik.getFieldProps("email")} disabled={isEmailDisabled} />
//                   {formik.touched.email && formik.errors.email ? <div className="error_div">{formik.errors.email}</div> : null}
//                 </div>

//                 <div className="name">
//                   <div className="email_mo_div">
//                     <label htmlFor="mobile" className="my_profile_label">
//                       Mobile
//                     </label>
//                     <span className="email_mo_update_btn" onClick={() => setIsMobileDisabled(false)}>
//                       <EditOutlined className="email_mo_update_btn_icon" />
//                       Change Mobile
//                     </span>
//                   </div>

//                   <input className="input_padding" type="text" id="mobile" {...formik.getFieldProps("mobile")} disabled={isMobileDisabled} />
//                   {formik.touched.mobile && formik.errors.mobile ? <div className="error_div">{formik.errors.mobile}</div> : null}
//                 </div>
//               </div>
//               <div className="profile_body">
//                 <div className="name">
//                   <label htmlFor="address" className="my_profile_label">
//                     Address
//                   </label>
//                   <input className="input_padding" type="text" id="address" {...formik.getFieldProps("address")} />
//                   {formik.touched.address && formik.errors.address ? <div className="error_div">{formik.errors.address}</div> : null}
//                 </div>

//                 <div className="name">
//                   <label htmlFor="profileLogo" className="my_profile_label">
//                     Profile Logo
//                   </label>
//                   {/* <input type="file" id="image" {...formik.getFieldProps("image")} />
//                   <div className="input_padding" style={{ height: "39px" }} >Upload File</div> */}
//                   <input
//                     type="file"
//                     id="image"
//                     {...formik.getFieldProps("image")}
//                     ref={fileInputRef}
//                     style={{ display: "none" }} // Hide the actual file input
//                   />
//                   <div className="input_padding" onClick={() => fileInputRef.current && fileInputRef.current.click()}>
//                     Click here to upload a file
//                   </div>
//                 </div>
//               </div>

//               <div className="profile_body">
//                 <div className="name">
//                   <label htmlFor="pinCode" className="my_profile_label">
//                     Pin Code
//                   </label>
//                   <input className="input_padding" type="text" id="pinCode" {...formik.getFieldProps("pinCode")} />
//                   {formik.touched.pinCode && formik.errors.pinCode ? <div className="error_div">{formik.errors.pinCode}</div> : null}
//                 </div>

//                 <div className="name">
//                   <label htmlFor="city" className="my_profile_label">
//                     City
//                   </label>
//                   <input className="input_padding" type="text" id="city" {...formik.getFieldProps("city")} />
//                   {formik.touched.city && formik.errors.city ? <div className="error_div">{formik.errors.city}</div> : null}
//                 </div>
//               </div>

//               <div className="profile_body">
//                 <div className="name">
//                   <label htmlFor="state" className="my_profile_label">
//                     State
//                   </label>
//                   <input className="input_padding" type="text" id="state" {...formik.getFieldProps("state")} />
//                   {formik.touched.state && formik.errors.state ? <div className="error_div">{formik.errors.state}</div> : null}
//                 </div>

//                 <div className="name">
//                   <label htmlFor="country" className="my_profile_label">
//                     Country
//                   </label>
//                   <input className="input_padding" type="text" id="country" {...formik.getFieldProps("country")} />
//                   {formik.touched.country && formik.errors.country ? <div className="error_div">{formik.errors.country}</div> : null}
//                 </div>
//               </div>
//             </div>
//             {validatedProfile.userType === "S" && (
//               <div className="profile_heading">
//                 <p className="profile_heading_p">Partner Information</p>
//               </div>
//             )}

//             <div className="profile_content">
//               {validatedProfile.userType === "S" && (
//                 <div className="profile_body ">
//                   <div className="name">
//                     <label htmlFor="parentName" className="my_profile_label">
//                       Parent Name
//                     </label>
//                     <input className="input_padding" type="text" id="parentName" {...formik.getFieldProps("parentName")} />
//                     {formik.touched.parentName && formik.errors.parentName ? <div className="error_div">{formik.errors.parentName}</div> : null}
//                   </div>
//                 </div>
//               )}
//               <div className="my_profile_div">
//                 <Button type="submit" className="my_profile_btn" onClick={() => formik.handleSubmit()}>
//                   Save Changes
//                 </Button>
//               </div>
//             </div>
//           </form>
//         </div>
//       </div>

//     </>
//   );
// };

// export default withProfile(MyProfileForm);

import React, { useEffect, useState } from "react";
import { Button, FormGroup, Label, ModalBody, ModalFooter, ModalHeader } from "reactstrap";
import { useFormik } from "formik";
import * as Yup from "yup";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { withProfile } from "../../../../contexts/profileContext";
import { useRef } from "react";
import { EditOutlined } from "@ant-design/icons";
import { Input, Modal } from "antd";
import bcrypt from "bcryptjs";

const MyProfileForm = ({ validatedProfile }) => {
  const navigate = useNavigate();
  const [defoultData, setDefoultData] = useState([]);
  const fileInputRef = useRef(null);
  useEffect(() => {
    printdata();
  }, []);

  const printdata = async () => {
    try {
      const payload = {
        userId: validatedProfile.userid,
      };
      const response = await axios.post(`${process.env.REACT_APP_API_URL}/user-details`, payload);

      const data = response.data.userDetails;

      setDefoultData(data);
      if (data) {
        formik.setValues({
          firstName: data.user_name.split(" ")[0] || "",
          lastName: data.user_name.split(" ")[1] || "",
          email: data.user_email || "",
          mobile: data.user_mobile || "",
          address: data.user_address || "",
          pinCode: data.pincode || "",
          city: data.user_city || "",
          state: data.user_state || "",
          country: data.user_country || "",
          parentName: data.user_company || "Default",
          image: "",
        });
      }
    } catch (error) {
      console.log(error);
    }
  };

  const validationSchema = Yup.object().shape({
    firstName: Yup.string().required("First Name is required"),
    lastName: Yup.string().required("Last Name is required"),
    email: Yup.string().email("Invalid email").required("Email is required"),
    mobile: Yup.string().required("Mobile is required"),
    address: Yup.string().required("Address is required"),
    pinCode: Yup.string().required("Pin Code is required"),
    city: Yup.string().required("City is required"),
    state: Yup.string().required("State is required"),
    country: Yup.string().required("Country is required"),
    parentName: Yup.string().required("Parent Name is required"),
  });

  const handleSubmit = async (values) => {
    values.username = values.firstName.concat(" ", values.lastName);
    delete values.firstName;
    delete values.lastName;
    let imageName = values.image.split("\\").pop();
    values.image = imageName;

    // console.log("values", values);

    try {
      const response = await axios.post(`${process.env.REACT_APP_API_URL}/fill-user-details`, values, {
        headers: {
          "Content-Type": "application/json",
        },
      });

      if (response.status === 200) {
        alert("Registration successful!");
        formik.resetForm();
        printdata();
      }
    } catch (error) {
      alert(error.response.data.message);
    }
  };

  const formik = useFormik({
    initialValues: {
      firstName: "",
      lastName: "",
      email: defoultData.user_email,
      mobile: "",
      address: "",
      pinCode: "",
      city: "",
      state: "",
      country: "",
      parentName: "" || null,
      image: "",
    },
    validationSchema: validationSchema,
    onSubmit: handleSubmit,
  });

  const [isMobileModalOpen, setIsMobileModalOpen] = useState(false);

  const [passwordValue, setPasswordValue] = useState("");

  const toggleMobileModal = () => setIsMobileModalOpen(!isMobileModalOpen);

  const handleMobileSubmit = async () => {
    if (!passwordValue) {
      alert("Fill details");
      return;
    }

    const hashedPassword = await hashWithFixedSalt(passwordValue);

    try {
      const payload = {
        mobile: formik.values.mobile,
        password: hashedPassword,
        userId: validatedProfile.userid,
      };
      console.log("payload", payload);

      const response = await axios.post(`${process.env.REACT_APP_API_URL}/fill-user-details`, payload, {
        headers: {
          "Content-Type": "application/json",
        },
      });

      if (response.status === 200) {
        alert("Mobile number updated successfully!");
        toggleMobileModal();
        setPasswordValue("");
      }
    } catch (error) {
      alert(error.response.data.message);
    }
  };
  async function hashWithFixedSalt(password) {
    const fixedSalt = "$2b$10$yourfixedsaltforyourapplication";
    const hashedPassword = await bcrypt.hash(password, fixedSalt);

    return hashedPassword;
  }

  const [isEmailModalOpen, setIsEmailModalOpen] = useState(false);
  const toggleEmailModal = () => setIsEmailModalOpen(!isEmailModalOpen);

  const handleEmailSubmit = async () => {
    if (!passwordValue) {
      alert("Fill details");
      return;
    }
    const hashedPassword = await hashWithFixedSalt(passwordValue);

    try {
      const payload = {
        email: formik.values.email,
        password: hashedPassword,
        userId: validatedProfile.userid,
      };

      // console.log("payload", payload);

      const response = await axios.post(`${process.env.REACT_APP_API_URL}/fill-user-details`, payload, {
        headers: {
          "Content-Type": "application/json",
        },
      });
      if (response.status === 200) {
        alert("Email updated successfully!");
        toggleEmailModal();
        setPasswordValue("");
      }
    } catch (error) {
      alert(error.response.data.message);
    }
  };

  return (
    <>
      <div className="page-content">
        <div className="my_profile">
          <div className="profile_heading">
            <p className="profile_heading_p">Personal Information</p>
            <Button onClick={() => navigate("/change-Password")}>Change password</Button>
          </div>
          <form className="my_profile_form" onSubmit={formik.handleSubmit}>
            <div className="profile_content">
              <div className="profile_body">
                <div className="name">
                  <label htmlFor="firstName" className="my_profile_label">
                    First Name
                  </label>
                  <input className="input_padding" type="text" id="firstName" {...formik.getFieldProps("firstName")} />

                  {formik.touched.firstName && formik.errors.firstName ? <div className="error_div">{formik.errors.firstName}</div> : null}
                </div>
                <div className="name">
                  <label htmlFor="lastName" className="my_profile_label">
                    Last Name
                  </label>
                  <input className="input_padding" type="text" id="lastName" {...formik.getFieldProps("lastName")} />
                  {formik.touched.lastName && formik.errors.lastName ? <div className="error_div">{formik.errors.lastName}</div> : null}
                </div>
              </div>
              <div className="profile_body">
                <div className="name ">
                  <div className="email_mo_div">
                    <label htmlFor="email" className="my_profile_label">
                      Email
                    </label>
                    <span className="email_mo_update_btn" onClick={toggleEmailModal}>
                      <EditOutlined className="email_mo_update_btn_icon" />
                      Change Email
                    </span>
                  </div>
                  <input className="input_padding" type="text" id="email" {...formik.getFieldProps("email")} disabled={true} />
                  {formik.touched.email && formik.errors.email ? <div className="error_div">{formik.errors.email}</div> : null}
                </div>

                <div className="name">
                  <div className="email_mo_div">
                    <label htmlFor="mobile" className="my_profile_label">
                      Mobile
                    </label>
                    <span className="email_mo_update_btn" onClick={toggleMobileModal}>
                      <EditOutlined className="email_mo_update_btn_icon" />
                      Change Mobile
                    </span>
                  </div>

                  <input className="input_padding" type="text" id="mobile" {...formik.getFieldProps("mobile")} disabled={true} />
                  {formik.touched.mobile && formik.errors.mobile ? <div className="error_div">{formik.errors.mobile}</div> : null}
                </div>
              </div>
              <div className="profile_body">
                <div className="name">
                  <label htmlFor="address" className="my_profile_label">
                    Address
                  </label>
                  <input className="input_padding" type="text" id="address" {...formik.getFieldProps("address")} />
                  {formik.touched.address && formik.errors.address ? <div className="error_div">{formik.errors.address}</div> : null}
                </div>

                <div className="name">
                  <label htmlFor="profileLogo" className="my_profile_label">
                    Profile Logo
                  </label>

                  <input type="file" id="image" {...formik.getFieldProps("image")} ref={fileInputRef} style={{ display: "none" }} />
                  <div className=" input_Profile_Logo_padding" onClick={() => fileInputRef.current && fileInputRef.current.click()}>
                    Click here to upload a file
                  </div>
                </div>
              </div>

              <div className="profile_body">
                <div className="name">
                  <label htmlFor="pinCode" className="my_profile_label">
                    Pin Code
                  </label>
                  <input className="input_padding" type="text" id="pinCode" {...formik.getFieldProps("pinCode")} />
                  {formik.touched.pinCode && formik.errors.pinCode ? <div className="error_div">{formik.errors.pinCode}</div> : null}
                </div>

                <div className="name">
                  <label htmlFor="city" className="my_profile_label">
                    City
                  </label>
                  <input className="input_padding" type="text" id="city" {...formik.getFieldProps("city")} />
                  {formik.touched.city && formik.errors.city ? <div className="error_div">{formik.errors.city}</div> : null}
                </div>
              </div>

              <div className="profile_body">
                <div className="name">
                  <label htmlFor="state" className="my_profile_label">
                    State
                  </label>
                  <input className="input_padding" type="text" id="state" {...formik.getFieldProps("state")} />
                  {formik.touched.state && formik.errors.state ? <div className="error_div">{formik.errors.state}</div> : null}
                </div>

                <div className="name">
                  <label htmlFor="country" className="my_profile_label">
                    Country
                  </label>
                  <input className="input_padding" type="text" id="country" {...formik.getFieldProps("country")} />
                  {formik.touched.country && formik.errors.country ? <div className="error_div">{formik.errors.country}</div> : null}
                </div>
              </div>
            </div>
            {validatedProfile.userType === "S" && (
              <div className="profile_heading">
                <p className="profile_heading_p">Partner Information</p>
              </div>
            )}

            <div className="profile_content">
              {validatedProfile.userType === "S" && (
                <div className="profile_body ">
                  <div className="name">
                    <label htmlFor="parentName" className="my_profile_label">
                      Parent Name
                    </label>
                    <input className="input_padding" type="text" id="parentName" {...formik.getFieldProps("parentName")} />
                    {formik.touched.parentName && formik.errors.parentName ? <div className="error_div">{formik.errors.parentName}</div> : null}
                  </div>
                </div>
              )}
              <div className="my_profile_div">
                <Button type="submit" className="my_profile_btn" onClick={() => formik.handleSubmit()}>
                  Save Changes
                </Button>
              </div>
            </div>
          </form>
        </div>
      </div>

      <Modal
        title="Change Mobile Number"
        visible={isMobileModalOpen} // Use visible instead of isOpen
        onCancel={toggleMobileModal} // to close the modal on click outside or on close icon
        footer={[
          <Button key="submit" type="primary" onClick={handleMobileSubmit}>
            Submit
          </Button>,
        ]}
      >
        <FormGroup>
          <Label for="mobileInput">New Mobile Number</Label>
          <Input className="input_padding" type="text" id="mobile" {...formik.getFieldProps("mobile")} />
          {formik.touched.mobile && formik.errors.mobile ? <div className="error_div">{formik.errors.mobile}</div> : null}
        </FormGroup>
        <FormGroup>
          <Label for="passwordInput">Password</Label>
          <Input type="password" id="passwordInput" value={passwordValue} onChange={(e) => setPasswordValue(e.target.value)} />
        </FormGroup>
      </Modal>

      <Modal
        title="Change Email ID"
        visible={isEmailModalOpen} // Use visible instead of isOpen
        onCancel={toggleEmailModal} // to close the modal on click outside or on close icon
        footer={[
          <Button key="submit" type="primary" onClick={handleEmailSubmit}>
            Submit
          </Button>,
        ]}
      >
        <FormGroup>
          <Label for="emailInput">New Email ID</Label>
          <Input className="input_padding" type="text" id="email" {...formik.getFieldProps("email")} />
          {formik.touched.email && formik.errors.email ? <div className="error_div">{formik.errors.email}</div> : null}
        </FormGroup>

        <FormGroup>
          <Label for="passwordInput">Password</Label>
          <Input type="password" id="passwordInput" value={passwordValue} onChange={(e) => setPasswordValue(e.target.value)} />
        </FormGroup>
      </Modal>
    </>
  );
};

export default withProfile(MyProfileForm);
