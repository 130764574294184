import React, { useState, useEffect } from "react";
import { Table, Checkbox, Button } from "antd";
import { ArrowLeftOutlined } from "@ant-design/icons";
import { Link, useLocation, useNavigate } from "react-router-dom";
import axios from "axios";
import { withProfile } from "../../../../../contexts/profileContext";

const AddRole = ({ validatedProfile }) => {
  const [roleName, setRoleName] = useState("");
  const [allFullAccess, setAllFullAccess] = useState(false);
  const [permissions, setPermissions] = useState({});
  const [roleNameError, setRoleNameError] = useState("");
  const navigate = useNavigate();
  const location = useLocation();
  let { editkey } = location.state || {};

  useEffect(() => {
    if (editkey) {
      fetchData();
    } else {
      initializePermissions();
    }
  }, [editkey]);

  const fetchData = async () => {
    const parentUserId = { srno: editkey };

    try {
      const response = await axios.post(`${process.env.REACT_APP_API_URL}/getRole`, parentUserId);
      const filteredRoleData = response.data.userDetails[0];

      const keysToRemove = ["modified_by", "modified_on", "created_by", "created_on"];

      const roleData = Object.fromEntries(Object.entries(filteredRoleData).filter(([key]) => !keysToRemove.includes(key)));

      setRoleName(roleData.role_name);
      setAllFullAccess(roleData.all_full_access);

      const updatedPermissions = {};
      Object.keys(roleData).forEach((key) => {
        if (key !== "role_name" && key !== "all_full_access" && key !== "srno" && key !== "user_master_id" && key !== "company_master_id") {
          updatedPermissions[key] = {
            FullAccess: roleData[key] === "F",
            View: roleData[key] === "F" || roleData[key] === "V" || roleData[key] === "CR" || roleData[key] === "D",
            CE: roleData[key] === "F" || roleData[key] === "CR",
            Delete: roleData[key] === "F" || roleData[key] === "D",
          };
        }
      });

      setPermissions(updatedPermissions);
    } catch (error) {
      console.error("Error fetching role details:", error);
    }
  };

  const initializePermissions = () => {
    setPermissions({
      bulk_upload: { FullAccess: false, View: false, CE: false, Delete: false },
      banking: { FullAccess: false, View: false, CE: false, Delete: false },
      "sales/excel": { FullAccess: false, View: false, CE: false, Delete: false },
      sales_return: { FullAccess: false, View: false, CE: false, Delete: false },
      "purchase/excel": { FullAccess: false, View: false, CE: false, Delete: false },
      purchase_return: { FullAccess: false, View: false, CE: false, Delete: false },
      journal: { FullAccess: false, View: false, CE: false, Delete: false },
      ledgerbulk: { FullAccess: false, View: false, CE: false, Delete: false },
      "item/excel": { FullAccess: false, View: false, CE: false, Delete: false },
      reports: { FullAccess: false, View: false, CE: false, Delete: false },
      master: { FullAccess: false, View: false, CE: false, Delete: false },
      ledgermaster: { FullAccess: false, View: false, CE: false, Delete: false },
      item: { FullAccess: false, View: false, CE: false, Delete: false },
      ruelist: { FullAccess: false, View: false, CE: false, Delete: false },
      transactions: { FullAccess: false, View: false, CE: false, Delete: false },
      tc_sales: { FullAccess: false, View: false, CE: false, Delete: false },
      tc_purchase: { FullAccess: false, View: false, CE: false, Delete: false },
      tc_payment: { FullAccess: false, View: false, CE: false, Delete: false },
      tc_receipt: { FullAccess: false, View: false, CE: false, Delete: false },
      tc_contra: { FullAccess: false, View: false, CE: false, Delete: false },
      autosale: { FullAccess: false, View: false, CE: false, Delete: false },
      document: { FullAccess: false, View: false, CE: false, Delete: false },
      settings: { FullAccess: false, View: false, CE: false, Delete: false },
      my_profile: { FullAccess: false, View: false, CE: false, Delete: false },
    });
  };

  const handleRoleNameChange = (e) => {
    setRoleName(e.target.value);
    setRoleNameError("");
  };

  const handleAllFullAccessChange = (e) => {
    const checked = e.target.checked;
    setAllFullAccess(checked);
    setPermissions((prevPermissions) => {
      const updatedPermissions = { ...prevPermissions };
      Object.keys(updatedPermissions).forEach((key) => {
        updatedPermissions[key] = {
          FullAccess: checked,
          View: checked,
          CE: checked,
          Delete: checked,
        };
      });

      return updatedPermissions;
    });
  };

  // const handlePermissionChange = (permission, field, value) => {
  //   // console.log("permission", permission);
  //   setPermissions((prevPermissions) => {
  //     const updatedPermissions = { ...prevPermissions };
  //     updatedPermissions[permission][field] = value;

  //     const handleCheckboxClick = (permissions) => {
  //       if (field === "FullAccess") {
  //         if (value) {
  //           permissions.FullAccess = permissions.CE = permissions.Delete = permissions.View = true;
  //         } else {
  //           permissions.FullAccess = permissions.CE = permissions.Delete = permissions.View = false;
  //         }
  //       } else if (field === "CE") {
  //         permissions.CE = value;
  //         if (value) {
  //           permissions.View = true;
  //         }
  //       } else if (field === "Delete") {
  //         permissions.Delete = value;
  //         if (value) {
  //           permissions.View = true;
  //         }
  //       } else if (field === "View") {
  //         permissions.View = value;
  //       }
  //     };

  //     handleCheckboxClick(updatedPermissions[permission]);

  //     const bulkRelevantPermissions = ["banking", "sales/excel", "sales_return", "purchase/excel", "purchase_return", "journal", "ledgerbulk", "item/excel"];
  //     const masterRelevantPermissions = ["ledgermaster", "item", "ruelist"];
  //     const transactionRelevantPermissions = ["tc_sales", "tc_purchase", "tc_payment", "tc_receipt", "tc_contra"];
  //     // const settingsRelevantPermissions = ["my_profile", "my_plan", "users", "role_management"];
  //     const settingsRelevantPermissions = ["my_profile"];

  //     const relevantPermissionsMap = {
  //       bulk_upload: bulkRelevantPermissions,
  //       master: masterRelevantPermissions,
  //       transactions: transactionRelevantPermissions,
  //       settings: settingsRelevantPermissions,
  //     };

  //     // Function to update related permissions based on clicked checkbox
  //     const updateRelatedPermissions = (relevantPermissions, field, value) => {
  //       relevantPermissions.forEach((key) => {
  //         if (!updatedPermissions[key]) {
  //           updatedPermissions[key] = { FullAccess: false, CE: false, Delete: false, View: false };
  //         }
  //         updatedPermissions[key][field] = value;
  //         if (field === "CE" && value) {
  //           updatedPermissions[key].View = true;
  //         }
  //         if (field === "Delete" && value) {
  //           updatedPermissions[key].View = true;
  //         }
  //       });
  //     };

  //     // Update related permissions based on the permission type
  //     if (permission in relevantPermissionsMap) {
  //       updateRelatedPermissions(relevantPermissionsMap[permission], field, value);
  //     }

  //     if (field === "FullAccess" && value) {
  //       const applyFullAccess = (relevantPermissions) => {
  //         relevantPermissions.forEach((key) => {
  //           if (!updatedPermissions[key]) {
  //             updatedPermissions[key] = { FullAccess: false, CE: false, Delete: false, View: false };
  //           }
  //           updatedPermissions[key].FullAccess = true;
  //           updatedPermissions[key].CE = true;
  //           updatedPermissions[key].Delete = true;
  //           updatedPermissions[key].View = true;
  //         });
  //       };

  //       if (permission === "bulk_upload") {
  //         applyFullAccess(bulkRelevantPermissions);
  //       } else if (permission === "master") {
  //         applyFullAccess(masterRelevantPermissions);
  //       } else if (permission === "transactions") {
  //         applyFullAccess(transactionRelevantPermissions);
  //       } else if (permission === "settings") {
  //         applyFullAccess(settingsRelevantPermissions);
  //       }
  //     }

  //     const checkAndUpdateFullAccess = (relevantPermissions, target) => {
  //       const anyChecked = relevantPermissions.some((key) => {
  //         if (!updatedPermissions[key]) {
  //           updatedPermissions[key] = { FullAccess: false, CE: false, Delete: false, View: false };
  //         }
  //         return updatedPermissions[key].FullAccess || updatedPermissions[key].View || updatedPermissions[key].CE || updatedPermissions[key].Delete;
  //       });
  //       updatedPermissions[target].FullAccess = anyChecked;
  //     };

  //     checkAndUpdateFullAccess(bulkRelevantPermissions, "bulk_upload");
  //     checkAndUpdateFullAccess(masterRelevantPermissions, "master");
  //     checkAndUpdateFullAccess(transactionRelevantPermissions, "transactions");
  //     checkAndUpdateFullAccess(settingsRelevantPermissions, "settings");

  //     const updateFullAccessForAll = () => {
  //       Object.keys(updatedPermissions).forEach((key) => {
  //         if (!updatedPermissions[key].View || !updatedPermissions[key].CE || !updatedPermissions[key].Delete) {
  //           updatedPermissions[key].FullAccess = false;
  //         } else {
  //           updatedPermissions[key].FullAccess = true;
  //         }
  //       });
  //     };

  //     updateFullAccessForAll();

  //     const anyUnchecked = Object.values(updatedPermissions).some((permission) => {
  //       return !permission.FullAccess || !permission.View || !permission.CE || !permission.Delete;
  //     });

  //     if (anyUnchecked) {
  //       setAllFullAccess(false);
  //     } else {
  //       setAllFullAccess(true);
  //     }

  //     return updatedPermissions;
  //   });
  // };

  const handlePermissionChange = (permission, field, value) => {
    setPermissions((prevPermissions) => {
      const updatedPermissions = { ...prevPermissions };
      updatedPermissions[permission][field] = value;

      const handleCheckboxClick = (permissions) => {
        if (field === "FullAccess") {
          permissions.FullAccess = value;
          permissions.CE = permissions.Delete = permissions.View = value;
        } else if (field === "CE" || field === "Delete") {
          permissions[field] = value;
          if (value) {
            permissions.View = true;
          }
        } else if (field === "View") {
          permissions.View = value;
        }
      };

      handleCheckboxClick(updatedPermissions[permission]);

      const bulkRelevantPermissions = ["banking", "sales/excel", "sales_return", "purchase/excel", "purchase_return", "journal", "ledgerbulk", "item/excel"];
      const masterRelevantPermissions = ["ledgermaster", "item", "ruelist"];
      const transactionRelevantPermissions = ["tc_sales", "tc_purchase", "tc_payment", "tc_receipt", "tc_contra"];
      const settingsRelevantPermissions = ["my_profile"];

      const relevantPermissionsMap = {
        bulk_upload: bulkRelevantPermissions,
        master: masterRelevantPermissions,
        transactions: transactionRelevantPermissions,
        settings: settingsRelevantPermissions,
      };

      // Helper function to update main permission based on relevant permissions state
      const updateMainPermission = (permissionType, relevantPermissions) => {
        const anyChecked = relevantPermissions.some((key) => {
          if (!updatedPermissions[key]) {
            updatedPermissions[key] = { FullAccess: false, CE: false, Delete: false, View: false };
          }
          return updatedPermissions[key].FullAccess || updatedPermissions[key].View || updatedPermissions[key].CE || updatedPermissions[key].Delete;
        });
        updatedPermissions[permissionType].View = anyChecked;
      };

      if (bulkRelevantPermissions.includes(permission)) {
        updateMainPermission("bulk_upload", bulkRelevantPermissions);
      } else if (masterRelevantPermissions.includes(permission)) {
        updateMainPermission("master", masterRelevantPermissions);
      } else if (transactionRelevantPermissions.includes(permission)) {
        updateMainPermission("transactions", transactionRelevantPermissions);
      }

      if (field === "FullAccess" && value) {
        const applyFullAccess = (relevantPermissions) => {
          relevantPermissions.forEach((key) => {
            if (!updatedPermissions[key]) {
              updatedPermissions[key] = { FullAccess: false, CE: false, Delete: false, View: false };
            }
            updatedPermissions[key].FullAccess = true;
            updatedPermissions[key].CE = true;
            updatedPermissions[key].Delete = true;
            updatedPermissions[key].View = true;
          });
        };

        if (permission === "bulk_upload") {
          applyFullAccess(bulkRelevantPermissions);
        } else if (permission === "master") {
          applyFullAccess(masterRelevantPermissions);
        } else if (permission === "transactions") {
          applyFullAccess(transactionRelevantPermissions);
        } else if (permission === "settings") {
          applyFullAccess(settingsRelevantPermissions);
        }
      }

      const checkAndUpdateFullAccess = (relevantPermissions, target) => {
        const anyChecked = relevantPermissions.some((key) => {
          if (!updatedPermissions[key]) {
            updatedPermissions[key] = { FullAccess: false, CE: false, Delete: false, View: false };
          }
          return updatedPermissions[key].FullAccess || updatedPermissions[key].View || updatedPermissions[key].CE || updatedPermissions[key].Delete;
        });
        updatedPermissions[target].FullAccess = anyChecked;
      };

      checkAndUpdateFullAccess(bulkRelevantPermissions, "bulk_upload");
      checkAndUpdateFullAccess(masterRelevantPermissions, "master");
      checkAndUpdateFullAccess(transactionRelevantPermissions, "transactions");
      checkAndUpdateFullAccess(settingsRelevantPermissions, "settings");

      const updateFullAccessForAll = () => {
        Object.keys(updatedPermissions).forEach((key) => {
          if (!updatedPermissions[key].View || !updatedPermissions[key].CE || !updatedPermissions[key].Delete) {
            updatedPermissions[key].FullAccess = false;
          } else {
            updatedPermissions[key].FullAccess = true;
          }
        });
      };

      updateFullAccessForAll();

      const anyUnchecked = Object.values(updatedPermissions).some((permission) => {
        return !permission.FullAccess || !permission.View || !permission.CE || !permission.Delete;
      });

      if (anyUnchecked) {
        setAllFullAccess(false);
      } else {
        setAllFullAccess(true);
      }

      return updatedPermissions;
    });
  };

  const getPermissionValue = (permission) => {
    // console.log("permission", permission);
    if (permission.FullAccess) return "F";
    if (permission.Delete && permission.CE && permission.View) return "F";
    if (permission.CE) return "CR";
    if (permission.Delete) return "D";
    if (permission.View) return "V";
    return null;
  };

  const handleSubmit = async () => {
    if (!roleName) {
      setRoleNameError("Please enter Role Name");
      return;
    }

    const hasAccess = Object.values(permissions).some((perm) => perm.FullAccess || perm.View || perm.CE || perm.Delete);

    if (!hasAccess) {
      alert("Please select at least one permission.");
      return;
    }

    const roleData = {
      userId: validatedProfile.userType === "S" ? validatedProfile.parentid : validatedProfile.userid,
      // companyId: validatedProfile.companyId,
      role_name: roleName,
      all_full_access: allFullAccess,
      ...(editkey && { editkey }),
      ...Object.keys(permissions).reduce((acc, key) => {
        const value = getPermissionValue(permissions[key]);
        if ((key === "sales/excel" || key === "purchase/excel" || key === "item/excel") && !editkey) {
          key = key === "sales/excel" ? "`sales/excel`" : key === "purchase/excel" ? "`purchase/excel`" : key === "item/excel" ? "`item/excel`" : key;
        }
        acc[key] = value;
        return acc;
      }, {}),
    };

    try {
      if (editkey) {
        await axios.post(`${process.env.REACT_APP_API_URL}/createroles`, roleData);
      } else {
        await axios.post(`${process.env.REACT_APP_API_URL}/createroles`, roleData);
      }
      navigate("/role-management");
    } catch (error) {
      console.error("Error:", error);
    }
  };

  const columns = [
    {
      title: "Sr. No.",
      dataIndex: "srno",
      key: "srno",
      render: (_, record, index) => index + 1,
    },
    {
      title: "Permission Name",
      dataIndex: "PermissionName",
      key: "PermissionName",
    },
    {
      title: "Full Access",
      dataIndex: "FullAccess",
      key: "FullAccess",
      render: (_, record) => <Checkbox checked={permissions[record.PermissionName]?.FullAccess} onChange={(e) => handlePermissionChange(record.PermissionName, "FullAccess", e.target.checked)} />,
    },
    {
      title: "View",
      dataIndex: "View",
      key: "View",
      render: (_, record) => <Checkbox checked={permissions[record.PermissionName]?.View} onChange={(e) => handlePermissionChange(record.PermissionName, "View", e.target.checked)} />,
    },
    {
      title: "Create/Edit",
      dataIndex: "CE",
      key: "CE",
      render: (_, record) => <Checkbox checked={permissions[record.PermissionName]?.CE} onChange={(e) => handlePermissionChange(record.PermissionName, "CE", e.target.checked)} />,
    },
    {
      title: "Delete",
      dataIndex: "Delete",
      key: "Delete",
      render: (_, record) => <Checkbox checked={permissions[record.PermissionName]?.Delete} onChange={(e) => handlePermissionChange(record.PermissionName, "Delete", e.target.checked)} />,
    },
  ];

  const dataSource = Object.keys(permissions).map((key, index) => ({
    key: index + 1,
    PermissionName: key,
    FullAccess: permissions[key].FullAccess,
    View: permissions[key].View,
    CE: permissions[key].CE,
    Delete: permissions[key].Delete,
  }));

  return (
    <div className="page-content">
      <div className="addRole">
        <div className="addRoleInner">
          <div className="addRoleHeader mb-3">
            <div className="d-flex justify-content-between">
              <div className="addRoleHeaderIner d-flex justify-content-start">
                <div className="HeaderRoleName mr-4">
                  <p className="mb-2 font-weight-bold">
                    <span className="mr-2 text-danger">*</span>Role name
                  </p>
                  <input type="text" name="rolename" id="roleName" placeholder="Enter Role Name" value={roleName} onChange={handleRoleNameChange} />
                  {roleNameError && <p className="text-danger mt-2">{roleNameError}</p>}
                </div>
                <div className="HeaderFullAccess align-items-end">
                  <>
                    <label className="accessLable" htmlFor="fullaccess">
                      All Full Access:
                    </label>
                    <Checkbox checked={allFullAccess} onChange={handleAllFullAccessChange} />
                  </>
                </div>
              </div>
              <div className="addRoleHeader mb-3 align-items-end">
                <Button type="dashed">
                  <Link className="backto-rolemanagement" to="/role-management">
                    <ArrowLeftOutlined />
                  </Link>
                </Button>
              </div>
            </div>
          </div>
          <div className="addRoleBody">
            <Table columns={columns} dataSource={dataSource} size="large" pagination={false} scroll={{ y: "60vh" }} />
          </div>
          <div className="addRoleFooter">
            <div className="addRoleButtonInner d-flex justify-content-end">
              <button className="bulkupload-btn border-primary">
                <Link to="/role-management">Cancel</Link>
              </button>
              <button className="bulkupload-btn btn-primary border-primary" onClick={handleSubmit}>
                Create Role
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default withProfile(AddRole);
