import React, { useState, useEffect } from "react";

const BulkUpload = () => {
  return (
    <>
      <div className="page-content">
        <div className="bulkupload-body">
          <h1>bulkupload</h1>
        </div>
      </div>
    </>
  );
};

export default BulkUpload;
