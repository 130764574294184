import React from 'react'
import { Button, Flex } from 'antd';

const Dashboard = () => {
    return (
        <>
            <div className='page-content'>
                Dashboard
            </div>
        </>
    )
}

export default Dashboard;