import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { useLocation } from "react-router-dom";

import data from "../../../../locales/en.json";
import TableAll from "../../../../Layout/MainTable/Table";

const Banking = () => {
  return (
    <>
      <div className="page-content">
        <TableAll />
      </div>
    </>
  );
};

export default Banking;
