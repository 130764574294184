import axios from "axios";
import React, { useEffect } from "react";

const PurchaseExcel = () => {
  return (
    <>
      <div className="page-content">
        <div>PurchaseExcel</div>
      </div>
    </>
  );
};

export default PurchaseExcel;
