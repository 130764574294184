import React, { useEffect, useState } from "react";
import { ArrowLeftOutlined, SnippetsOutlined, YoutubeOutlined, SettingOutlined } from "@ant-design/icons";
import { Switch, Button, Steps } from "antd";
import { navigate, useLocation, useNavigate } from "react-router-dom";
import SimpleBar from "simplebar-react";
import "simplebar-react/dist/simplebar.min.css";
import axios from "axios";
import { withProfile } from "../../contexts/profileContext";

const initialLocalMapedTable = ["REFERANCE NO", "INVOICE DATE", "PARTY A/C NAME", "PLACE OF SUPPLY", "SALES LEDGER", "NAME OF ITEM", "QUANTITY", "RATE", "AMOUNT"];
const initialExportMapedTable = ["REFERANCE NO", "INVOICE DATE", "PARTY A/C NAME", "PLACE OF SUPPLY", "STATE OF SUPPLY", "SALES LEDGER", "NAME OF ITEM", "QUANTITY", "RATE", "AMOUNT"];
// const initialUnmapedTable = ["GST NO", "SGST", "CGST", "IGST", "TOTAL AMOUNT"];
const initialUnmapedTable = ["SGST", "CGST", "IGST", "TOTAL AMOUNT"];

// // second page data
const secondPageTaxLedger = ["SGST ledger", "CGST ledger", "IGST ledger"];
const ledgerInTally = ["SGST", "CGST", "IGST"];
const secondPageSheetCheck = ["SGST", "CGST", "IGST"];

const Mapping = ({ validatedProfile, fetchSubTableData, fetchMainTableData }) => {
  const location = useLocation();
  const navigate = useNavigate();
  const [lutExportToggal, setLutExportToggal] = useState(false); // Toggle button for country and state column
  const { columnheading, pathWithoutSlash, pathOnlyName, json, fileName } = location.state || {};

  const [mapedTable, setMapedTable] = useState([]);
  const [unmapedTable, setUnmapedTable] = useState(initialUnmapedTable); // Set bd fild

  useEffect(() => {
    setMapedTable(lutExportToggal ? initialExportMapedTable : initialLocalMapedTable);
  }, [lutExportToggal, pathWithoutSlash]);

  //=======================================================

  const [mapedColumn, setMapedColumn] = useState([]);
  const [unmapedColumn, setUnmapedColumn] = useState([]);

  const mapedFilteredTable = columnheading.filter((column) => !initialUnmapedTable.includes(column)); // Excel file column
  const unMapedFilteredTable = columnheading.filter((column) => initialUnmapedTable.includes(column)); // Excel file column

  useEffect(() => {
    setMapedColumn(mapedFilteredTable.map((heading) => heading));
  }, []);

  useEffect(() => {
    setUnmapedColumn(unMapedFilteredTable.map((heading) => heading));
  }, []);

  const sheetData = columnheading.filter((column) => secondPageSheetCheck.includes(column));
  const [sheetDataColumn, setSheetDataColumn] = useState(sheetData.map((heading) => heading)); // Define sheetDataColumn

  const onChangeInvoice = (checked) => {
    console.log(`Switch to ${checked}`);
  };

  const mapedHandleChange = (event, index) => {
    const updatedSelectedCountries = [...mapedColumn];

    updatedSelectedCountries[index] = event.target.value;
    setMapedColumn(updatedSelectedCountries);
  };

  const unmapedHandleChange = (event, index) => {
    const updatedSelectedCountries = [...unmapedColumn];
    updatedSelectedCountries[index] = event.target.value;
    setUnmapedColumn(updatedSelectedCountries);
  };

  // console.log(`lutExportToggal`, lutExportToggal);

  const mappingarraymearge = () => {
    let keyNewMapping;
    if (pathWithoutSlash === "sales/excel") {
      keyNewMapping = lutExportToggal ? initialExportMapedTable.concat(initialUnmapedTable) : initialLocalMapedTable.concat(initialUnmapedTable);
    } else {
      keyNewMapping = mapedTable;
    }
    const valueNewMapping = mapedColumn.concat(unmapedColumn);
    const newArray = keyNewMapping.reduce((acc, key, index) => {
      acc[key] = valueNewMapping[index];
      return acc;
    }, {});

    // console.log("JSON Data:", json);
    // console.log("newArray db Data:", newArray);

    function updateKeys(json, newArray) {
      const mapKeys = (json) => {
        const updatedData = {};

        for (let originalKey in newArray) {
          const mappedKey = newArray[originalKey];
          originalKey = originalKey.toLowerCase();
          // console.log("mappedKey", mappedKey);
          if (json.hasOwnProperty(originalKey)) {
            // console.log("json[originalKey]", json[originalKey]);
            updatedData[mappedKey] = json[originalKey];
          } else {
            updatedData[mappedKey] = null;
          }
        }

        return updatedData;
      };

      const updatedJsonData = json.map(mapKeys);
      // console.log("updatedJsonData :", updatedJsonData);
      return updatedJsonData;
    }

    const updatedJsonData = updateKeys(json, newArray);

    const uploadToDatabase = async () => {
      try {
        let data = {
          userId: validatedProfile.userType === "S" ? validatedProfile.parentid : validatedProfile.userid,
          companyId: validatedProfile.companyId,
        };
        if (pathOnlyName === "item" || pathOnlyName === "ledgerbulk" || pathOnlyName === "sales" || pathOnlyName === "banking") {
          data[pathOnlyName + "data"] = updatedJsonData;
          data[pathOnlyName] = [{ NAME: fileName }];
          if (lutExportToggal) {
            data[pathOnlyName] = [{ NAME: fileName, type: "LUT EXPORT" }];
          } else {
            data[pathOnlyName] = [{ NAME: fileName, type: "LOCAL" }];
          }
        }
        // console.log("data", data);
        const response = await axios.post(`${process.env.REACT_APP_API_URL}/insertfileapi`, data, {
          headers: {
            "Content-Type": "application/json",
          },
        });
        fetchSubTableData();
        fetchMainTableData();
        navigate("/transactionstable", {
          state: { key: response.data.result.key, pathWithoutSlash },
        });
      } catch (error) {
        alert(error.response.data.message ? error.response.data.message : "Error in uploading data");
        navigate("/sales/excel");
      }
    };

    uploadToDatabase();
  };

  const [currentPage, setCurrentPage] = useState(0);

  const goToPage = (page) => {
    setCurrentPage(page);
  };

  const nextPage = () => {
    setCurrentPage(currentPage + 1);
  };

  const previousPage = () => {
    setCurrentPage(currentPage - 1);
  };

  const gobackpage = () => {
    navigate("/" + `${pathWithoutSlash}`);
  };
  const steps = [
    {
      title: "",
      content: "First-content",
    },
    {
      title: "",
      content: "Second-content",
    },
    {
      title: "",
      content: "Second-content",
    },
  ];

  return (
    <>
      <div className="mapping-outer">
        <div className="mapping-inner">
          <div className="mapping-heading-outer">
            <div className="mapping-heading">
              <div className="beck-btn">
                <Button onClick={gobackpage}>
                  <ArrowLeftOutlined />
                </Button>
                <p>
                  <SnippetsOutlined /> Map Fields
                </p>
              </div>

              <div className="Steps-div">
                <Steps current={currentPage} className="autosaleModel_steps">
                  {steps.map((item) => (
                    <Steps.Step key={item.title} title={item.title} />
                  ))}
                </Steps>
              </div>

              <div className="invoice-btn">
                <label htmlFor="" className="invoice-common">
                  Accounting Invoice
                </label>
                <Switch className="invoice-common" defaultChecked onChange={onChangeInvoice} />
                <label htmlFor="" className="invoice-common">
                  Item Invoice
                </label>
                <Button className="invoice-common Configration-btn" type="primary">
                  {" "}
                  <SettingOutlined /> Configration
                </Button>
                <button className="invoice-common mapping-ytb-btn btn-outline-danger bg-btn">
                  <YoutubeOutlined className="text-danger" />
                </button>
              </div>
            </div>
          </div>
          <div className="mapping-body-outer">
            LOCAL <Switch className="export-toggal-switch" defaultChecked={lutExportToggal} onChange={() => setLutExportToggal(!lutExportToggal)} /> LUT EXPORT
            {currentPage === 0 && (
              <div className="mapping-body " style={{ display: currentPage === 0 ? "flex" : "none" }}>
                <div className="maped-box maped-box-commun">
                  <div className="box-heding">
                    <p>Mapped</p>
                    <span>{mapedFilteredTable.length}</span>
                  </div>
                  <div className="table">
                    <table>
                      <tr>
                        <th>Tally Fields</th>
                        <th>Your Sheet Header</th>
                        <th>Your Sheet Data</th>
                      </tr>
                      <SimpleBar style={{ height: 420 }}>
                        {mapedTable.map((row, key) => (
                          <tr key={key}>
                            <td>{row}</td>
                            <td>
                              <select id="mappingColumn" value={mapedColumn[key]} onChange={(event) => mapedHandleChange(event, key)}>
                                {mapedFilteredTable.map((heading, key) => (
                                  <>
                                    <option key={key} value={heading}>
                                      {heading}
                                    </option>
                                  </>
                                ))}
                              </select>
                            </td>
                            <td>{row.column3}</td>
                          </tr>
                        ))}
                      </SimpleBar>
                    </table>
                  </div>
                </div>

                <div className="maped-box maped-box-commun">
                  <div className="box-heding">
                    <p>Unmapped</p>
                    <span>{unMapedFilteredTable.length}</span>
                  </div>
                  <div className="table">
                    <table>
                      <tr>
                        <th>Tally Fields</th>
                        <th>Your Sheet Header</th>
                        <th>Your Sheet Data</th>
                      </tr>
                      <SimpleBar style={{ height: 420 }}>
                        {unMapedFilteredTable.map((row, key) => (
                          <tr key={key}>
                            <td>{row}</td>
                            <td>
                              <select id="mappingColumn" value={unmapedColumn[key]} onChange={(event) => mapedHandleChange(event, key)}>
                                {unMapedFilteredTable.map((heading, key) => (
                                  <>
                                    <option key={key} value={heading}>
                                      {heading}
                                    </option>
                                  </>
                                ))}
                              </select>
                            </td>
                            <td>{row.column3}</td>
                          </tr>
                        ))}
                      </SimpleBar>
                    </table>
                  </div>
                </div>
              </div>
            )}
            {currentPage === 1 && (
              <div className="mapping-body" style={{ display: currentPage === 1 ? "block" : "none" }}>
                <div className="excel-confirm">
                  <div className="excel-confirm-btn">
                    <label htmlFor="">GST Ledger from excel sheet?</label>
                  </div>
                  <div className="excel-confirm-btn">
                    <label htmlFor="">GST Auto Calculation?</label>
                  </div>
                </div>
                <div className="maped-box-w-100 maped-box-commun">
                  <div className="table">
                    <table>
                      <tr>
                        <th>Tax Ledge</th>
                        <th>Tally Fields</th>
                        <th>Your Sheet Data</th>
                      </tr>
                      <SimpleBar>
                        {secondPageTaxLedger.map((row, key) => (
                          <tr key={key}>
                            <td>{row}</td>
                            <td>
                              <select id="mappingColumn" value={ledgerInTally[key]} onChange={(event) => mapedHandleChange(event, key)}>
                                {ledgerInTally.map((heading, key) => (
                                  <>
                                    <option key={key} value={heading}>
                                      {heading}
                                    </option>
                                  </>
                                ))}
                              </select>
                            </td>
                            <td>{row.column3}</td>
                          </tr>
                        ))}
                      </SimpleBar>
                    </table>
                  </div>
                  <div className="table">
                    <table>
                      <tr>
                        <th>Ledger Amount</th>
                        <th>Your Sheet Header</th>
                        <th> </th>
                      </tr>
                      <SimpleBar>
                        {secondPageTaxLedger.map((row, key) => (
                          <tr key={key}>
                            <td>{row}</td>
                            <td>
                              <select id="mappingColumn" value={sheetDataColumn[key]} onChange={(event) => mapedHandleChange(event, key)}>
                                {sheetData.map((heading, key) => (
                                  <>
                                    <option key={key} value={heading}>
                                      {heading}
                                    </option>
                                  </>
                                ))}
                              </select>
                            </td>
                            <td></td>
                          </tr>
                        ))}
                      </SimpleBar>
                    </table>
                  </div>
                </div>
                <div className="excel-confirm"></div>
              </div>
            )}
            {currentPage === 2 && (
              <div className="mapping-body" style={{ display: currentPage === 2 ? "block" : "none" }}>
                <div className="maped-box-w-100 maped-box-commun">
                  <div className="box-heding">
                    <p>Mapped</p>
                    <span>18</span>
                  </div>
                  <div className="table">
                    <table>
                      <tr>
                        <th>Tally Fields</th>
                        <th>Your Sheet Header</th>
                        <th>Your Sheet Data</th>
                        <th>Your Sheet Data</th>
                      </tr>
                      <SimpleBar>
                        {unmapedTable.map((row, key) => (
                          <tr key={key}>
                            <td>{row}</td>
                            <td>
                              <select id="mappingColumn" value={unmapedColumn[key]} onChange={(event) => unmapedHandleChange(event, key)}>
                                {unMapedFilteredTable.map((heading, key) => (
                                  <option key={key} value={heading}>
                                    {heading}
                                  </option>
                                ))}
                              </select>
                            </td>
                            <td>{row.column3}</td>
                            <td>{row.column3}</td>
                          </tr>
                        ))}
                      </SimpleBar>
                    </table>
                  </div>
                </div>
              </div>
            )}
          </div>

          <div className="mapping-footer-outer">
            <div className="mapping-footer">
              {currentPage !== 0 && (
                <Button className="mapping-btn" type="primary" onClick={previousPage}>
                  Previous
                </Button>
              )}
              {currentPage !== 2 && (
                <Button className="mapping-btn" type="primary" onClick={nextPage}>
                  Next
                </Button>
              )}
              {currentPage === 2 && (
                <Button className="mapping-btn" type="primary" onClick={mappingarraymearge}>
                  Save
                </Button>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

// export default Mapping;
export default withProfile(Mapping);
