import axios from "axios";
import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { Button, DatePicker, Modal, Select, Table } from "antd";
import { YoutubeOutlined, ArrowLeftOutlined, DoubleLeftOutlined, SnippetsOutlined, InfoCircleOutlined } from "@ant-design/icons";
import { withProfile } from "../../contexts/profileContext";
import SpliteTable from "./SplitPopUpBox/SplitTable";
import TallySyncTable from "./TallySyncPopUpBox/TallySyncTable";
import confirm from "antd/es/modal/confirm";
import ErrorModel from "./ErrorModel/ErrorModel";
import Spinner from "../../components/Common/Spinner/Spinner";
import AgGridSubTable from "./AgGridTable";
import Moment from "react-moment";
import { withRole } from "../../contexts/roleContext";

const TransactionsTable = ({ validatedProfile, subTableData, fetchSubTableData, role }) => {
  const [upadateLedger, setUpadateLedger] = useState([]); // For updating selected rows

  const [rowData, setRowData] = useState([]);
  const [fullRowData, setFullRowData] = useState([]);
  const [isLoading, setIsLoading] = useState(false); // New state for spinner
  const [ledgeroption, setLedgeroption] = useState([]);
  const [ledgerUnderSalesAccounts, setLedgerUnderSalesAccounts] = useState([]);
  const [stockitemNameOptions, setStockitemNameOptions] = useState([]);
  const [partyAcNameOptions, setPartyAcNameOptions] = useState([]);
  const [splitModalOpen, setSplitModalOpen] = useState(false);
  const [selectedSlpitIdData, setSelectedSlpitIdData] = useState(null);
  const [tallySyncModalOpen, setTallySyncModalOpen] = useState(false);
  const [selectedRowKeys, setSelectedRowKeys] = useState([]); // To store selected row keys
  const [selectedTallySyncId, setSelectedTallySyncId] = useState([]); // for data of TallySync id
  const [debitRs, setDebitRs] = useState(0);
  const [creditRs, setCreditRs] = useState(0);
  const [stockitemNameError, setStockitemNameError] = useState(false);
  const [ledgerNameError, setLedgerNameError] = useState(false);
  const [ErrorRowList, setErrorRowList] = useState([]);

  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);

  const saveButtonNotPermition = ["autosale"];
  const handleDateChange = (dates) => {
    if (dates) {
      setStartDate(dates[0]?.format("YYYY-MM-DD"));
      setEndDate(dates[1]?.format("YYYY-MM-DD"));
    } else {
      setStartDate(null);
      setEndDate(null);
    }
  };

  const navigate = useNavigate();

  const location = useLocation();
  // const currentPath = location.pathname;
  let { key, pathWithoutSlash } = location.state || {};
  const pathnamecapital = pathWithoutSlash.charAt(0).toUpperCase() + pathWithoutSlash.slice(1);

  const gobackpage = () => {
    navigate("/" + `${pathWithoutSlash}`);
  };

  useEffect(() => {
    if (stockitemNameError && ledgerNameError) {
      let errordata = [...stockitemNameError, ...ledgerNameError];

      // Sort by id and remove duplicates by id
      errordata = errordata
        .sort((a, b) => a.id - b.id) // Sort by id in ascending order
        .filter(
          (item, index, self) => index === self.findIndex((i) => i.id === item.id) // Keeps only the first occurrence of each id
        );

      // Set the unique error data
      setErrorRowList(errordata);
    }
  }, [stockitemNameError, ledgerNameError]);

  // console.log("ErrorRowList", ErrorRowList);

  useEffect(() => {
    viewAll();
  }, [subTableData]);

  let path;
  switch (pathWithoutSlash) {
    case "sales/excel":
      path = "salesdata";
      break;
    case "item/excel":
      path = "itemdata";
      break;
    case "ledgerbulk":
      path = "ledgerbulkdata";
      break;
    default:
      path = `${pathWithoutSlash}data`;
      break;
  }
  const viewAll = async () => {
    setIsLoading(true);

    try {
      // const response = await axios.post(`${process.env.REACT_APP_API_URL}/subtabledata`, {
      //   userId: validatedProfile.userType === "S" ? validatedProfile.parentid : validatedProfile.userid,
      //   companyId: validatedProfile.companyId,
      // });

      if (subTableData) {
        let Data = subTableData[path];

        Data = Data.filter((row) => {
          return row.srno === key;
        });

        if (pathWithoutSlash === "banking") {
          let forMergeVale = [];
          Data.forEach((entry) => {
            if (entry.issplit === "YES" && entry.primaryentry) {
              forMergeVale.push(entry);
            }
          });

          Data.forEach((entry) => {
            if (entry.issplit === "YES") {
              forMergeVale.forEach((splitentry) => {
                if (entry.id === splitentry.primaryentry) {
                  entry.debit += splitentry.debit;
                  entry.credit += splitentry.credit;
                }
              });
            }
          });

          Data = Data.filter((row) => {
            return row.primaryentry === null;
          });
        }

        if (pathWithoutSlash === "banking") {
          Data = Data.map(({ ...row }) => {
            const amount = row.debit > 0 ? row.debit : row.credit;
            const entries = Object.entries(row);
            entries.splice(8, 0, ["amount", amount]);
            return Object.fromEntries(entries);
          });
        }
        Data = Data.map((obj, index) => ({
          "Sr No": index + 1,
          ...Object.fromEntries(Object.entries(obj).map(([key, value]) => [key.replace(/_/g, " "), value])),
        }));

        setFullRowData(Data);
        setRowData(Data);
        // console.log("Data", Data);

        const { totalCredit, totalDebit } = Data.reduce(
          (totals, element) => {
            if (element.srno === key) {
              totals.totalCredit += element.credit;
              totals.totalDebit += element.debit;
            }
            return totals;
          },
          { totalCredit: 0, totalDebit: 0 }
        );

        setCreditRs(totalCredit);
        setDebitRs(totalDebit);

        const newLedgerOptions = subTableData["ledgermaster"]?.map((element) => element.name) || []; // for dropdown in ledger column
        const uniqueLedgerOptions = [...new Set(newLedgerOptions)];
        setLedgeroption(uniqueLedgerOptions);

        const newLedger = subTableData["ledgermaster"]?.map((element) => (element.under === "Sales Accounts" ? element.name : null)).filter(Boolean) || [];
        const uniqueLedgerUnderSalesAccounts = [...new Set(newLedger)];
        setLedgerUnderSalesAccounts(uniqueLedgerUnderSalesAccounts);

        const stockitemNameOptions = subTableData["stockitem"]?.map((element) => element.name) || []; // for dropdown in stockitem column
        const uniqueStockitemNameOptions = [...new Set(stockitemNameOptions)];
        setStockitemNameOptions(uniqueStockitemNameOptions);

        const partyAcNameOptions = subTableData["partyaccount"]?.map((element) => element.name) || []; // for dropdown in party ac name column
        const uniquePartyAcNameOptions = [...new Set(partyAcNameOptions)];
        setPartyAcNameOptions(uniquePartyAcNameOptions);
      }
    } catch (error) {
      console.error("Error:", error);
      setIsLoading(false);
    } finally {
      setIsLoading(false);
    }
  };

  const UpdateSelectedRow = () => {
    setIsLoading(true);
    let payload = { userId: validatedProfile.userType === "S" ? validatedProfile.parentid : validatedProfile.userid, companyId: validatedProfile.companyId, tablename: path, data: upadateLedger };

    axios
      .post(`${process.env.REACT_APP_API_URL}/UpdateSelectedLedgerRow`, payload)
      .then((response) => {
        if (response.status === 200) {
          fetchSubTableData();
          viewAll();
          setUpadateLedger([]);
          setIsLoading(false);
        }
      })
      .catch((error) => {
        setIsLoading(false);
        console.error("Error:", error);
      });
  };

  const moreInfoBtn = () => {
    setTallySyncModalOpen(!tallySyncModalOpen);
  };

  const handleOpenSlpitMenu = (Slpitid) => {
    const Slpitiddata = rowData.find((data) => data.id === Slpitid);

    setSelectedSlpitIdData(Slpitiddata);
    setSplitModalOpen(true);
  };
  const handleOpenTallySyncTable = () => {
    let dataForSendToTally = [];

    console.log("selectedRow length", selectedRowKeys.length);

    console.log("ErrorRow length", ErrorRowList.length);
    if (selectedRowKeys.length > 0) {
      let ledgerMissing = 0;
      selectedRowKeys.map((rowId) => {
        let selectedRow;
        if (pathWithoutSlash === "autosale") {
          selectedRow = rowData.find((row) => row.id === rowId.id && row.flag === "NS");
        } else if (pathWithoutSlash === "sales/excel") {
          selectedRow = rowData.find((row) => row.id === rowId.id && (row.flag === "NS" || row.flag === "SV"));
        } else if (pathWithoutSlash === "banking") {
          selectedRow = rowData.find((row) => row.id === rowId.id && row.flag === "SV");
        }
        if (selectedRow && pathWithoutSlash === "sales/excel") {
          if (!ErrorRowList.find((errorRow) => errorRow.id === selectedRow.id)) {
            dataForSendToTally.push(selectedRow);
          }
        } else if (selectedRow) {
          dataForSendToTally.push(selectedRow);
        }
      });

      console.log("SendToTally row length", dataForSendToTally.length);
      if (ledgerMissing === selectedRowKeys.length) {
        alert("All row have missing or unavailable ledger or Type.");
        return;
      }
      if (dataForSendToTally.length === 0) {
        alert("All saved row alredy synced or Not Saved any row .");
        return;
      }

      confirm({
        title: "Are you sure you want to push these entries to Tally??",

        async onOk() {
          try {
            console.log("dataForSendToTally", dataForSendToTally);
            setSelectedTallySyncId(dataForSendToTally);
            setTallySyncModalOpen(true);
          } catch (e) {
            return console.log("Oops errors!");
          }
        },
        onCancel() {},
      });
      setSelectedRowKeys([]);
    } else {
      alert("Please select at least 1 row in order to push data to Tally");
    }
  };

  const DeleteMultipleSelectedRow = () => {
    setIsLoading(true);
    let payload = { userId: validatedProfile.userType === "S" ? validatedProfile.parentid : validatedProfile.userid, companyId: validatedProfile.companyId, tablename: path, deleteID: selectedRowKeys };

    axios
      .post(`${process.env.REACT_APP_API_URL}/deleteData`, payload)
      .then((response) => {
        if (response.status === 200) {
          fetchSubTableData();
          viewAll();
          setSelectedRowKeys([]);
          setIsLoading(false);
        }
      })
      .catch((error) => {
        setIsLoading(false);
        console.error("Error:", error);
        setSelectedRowKeys([]);
      });
  };
  const [checkboxes, setCheckboxes] = useState({
    hideRecords: false,
    savedRecords: false,
    blankRecords: false,
    failedRecords: false,
  });

  const handleCheckboxChange = (e) => {
    const { name, checked } = e.target;
    setCheckboxes((prevCheckboxes) => ({
      ...prevCheckboxes,
      [name]: checked,
    }));

    if (name === "failedRecords") {
      if (checked) {
        // If checked, set to ErrorRowList
        setRowData(ErrorRowList);
      } else {
        // If unchecked, reset to fullRowData (or the default dataset)
        setRowData(fullRowData);
      }
    }
  };

  return (
    <>
      {isLoading && <Spinner isSpinner={isLoading} />}
      <div className="TransactionsTable">
        <div className="TransactionsTable-heading">
          <div className="table-title">
            <Button onClick={gobackpage}>
              <ArrowLeftOutlined />
            </Button>
            <p className="subtable-title-name">
              <SnippetsOutlined /> {pathnamecapital}
            </p>
            <span className="TransactionsNumber ">{rowData.length}</span>
          </div>
          <div className="table-company-title">
            <span>Company Name:</span>
            <p>{validatedProfile.companyName && validatedProfile.companyName.toUpperCase()}</p>
          </div>
        </div>
        <div className="TransactionsTable-top-heading">
          <div className="operations">
            <div className="operations-inner">
              <p>Bulk Operations </p>
              <small>
                <InfoCircleOutlined />
              </small>
            </div>
            <span>Update Bulk Records</span>
            <input type="file" name="" id="" />
          </div>
          <div className="filters">
            <p>
              General Filters{" "}
              <small>
                <InfoCircleOutlined />
              </small>
            </p>

            <div className="filters-checkbox">
              <div className="checkbox">
                <input type="checkbox" name="hideRecords" id="hide-records" checked={checkboxes.hideRecords} onChange={handleCheckboxChange} />
                <label htmlFor="hide-records">Hide Tally Synced Records</label>
              </div>
              <div className="checkbox">
                <input type="checkbox" name="savedRecords" id="saved-records" checked={checkboxes.savedRecords} onChange={handleCheckboxChange} />
                <label htmlFor="saved-records">Saved Records</label>
              </div>
              <div className="checkbox">
                <input type="checkbox" name="blankRecords" id="blank-records" checked={checkboxes.blankRecords} onChange={handleCheckboxChange} />
                <label htmlFor="blank-records">Blank Records</label>
              </div>
              <div className="checkbox">
                <input type="checkbox" name="failedRecords" id="failed-records" checked={checkboxes.failedRecords} onChange={handleCheckboxChange} />
                <label htmlFor="failed-records">Failed Records</label>
              </div>
            </div>
            <div className="filters-date">
              <div className="date-filter">
                <DatePicker.RangePicker onChange={handleDateChange} />
              </div>

              {pathWithoutSlash === "banking" ? (
                <div className="creditdebit">
                  <span className="credit-Rs Rs-text ">credit:</span>
                  <p className="credit-amount">{new Intl.NumberFormat("en-IN", { minimumFractionDigits: 2, maximumFractionDigits: 2 }).format(creditRs)}</p>
                  <span className="debit-Rs Rs-text">debit:</span>
                  <p className="debit-amount">{new Intl.NumberFormat("en-IN", { minimumFractionDigits: 2, maximumFractionDigits: 2 }).format(debitRs)}</p>
                </div>
              ) : (
                ""
              )}
            </div>
          </div>
          <div className="controal-btn">
            <div className="controal-btn-line">
              <div className="moreinfo-btn-menu all-btn">
                <Button onClick={moreInfoBtn}>
                  <InfoCircleOutlined />
                  More info
                </Button>
              </div>

              <div className="create-btn-menu all-btn">
                <Button>
                  <InfoCircleOutlined />
                  Create Ledger
                </Button>
              </div>
              {!saveButtonNotPermition.includes(pathWithoutSlash) && (
                <div className=" all-btn">
                  <Button type="primary" onClick={UpdateSelectedRow}>
                    Save
                  </Button>
                </div>
              )}
            </div>

            <div className="controal-btn-line">
              <div className="save-btn-menu all-btn">
                <Button onClick={handleOpenTallySyncTable}>Send to Tally</Button>
              </div>

              <div className="all-btn">
                <button className="bulkupload-topbar-btn bulkupload-btn btn-outline-danger bg-btn all-btn">
                  <YoutubeOutlined className="topobar-icon text-danger " />
                </button>
              </div>
            </div>
            <Button type="primary TransactionsTable-sidemeny-btn">
              <DoubleLeftOutlined />{" "}
            </Button>
          </div>
        </div>
        <AgGridSubTable
          role={role}
          oldPathWithoutSlash={pathWithoutSlash}
          RowKey={key}
          path={path}
          setUpadateLedger={setUpadateLedger}
          viewAll={viewAll}
          ledgeroption={ledgeroption}
          ledgerUnderSalesAccounts={ledgerUnderSalesAccounts}
          stockitemNameOptions={stockitemNameOptions}
          partyAcNameOptions={partyAcNameOptions}
          rowData={rowData}
          selectedSlpitIdData={selectedSlpitIdData}
          handleOpenSlpitMenu={handleOpenSlpitMenu}
          selectedRowKeys={selectedRowKeys}
          setSelectedRowKeys={setSelectedRowKeys}
          startDate={startDate}
          endDate={endDate}
          setLedgerNameError={setLedgerNameError}
          setStockitemNameError={setStockitemNameError}
        />

        {selectedRowKeys.length > 1 && role && ["D", "F"].includes(role["/" + `${pathWithoutSlash}`]) ? (
          <Button type="primary" className="TransactionsTable-delete-btn" onClick={DeleteMultipleSelectedRow}>
            Delete Row
          </Button>
        ) : (
          <Button type="primary" className="TransactionsTable-delete-btn" onClick={DeleteMultipleSelectedRow} disabled>
            Delete Row
          </Button>
        )}
      </div>
      {splitModalOpen ? <SpliteTable splitModalOpen={splitModalOpen} fetchSubTableData={fetchSubTableData} validatedProfile={validatedProfile} setSplitModalOpen={setSplitModalOpen} pathPlusData={path} ledgeroption={ledgeroption} selectedSlpitIdData={selectedSlpitIdData} viewAll={viewAll} /> : ""}

      {tallySyncModalOpen ? <TallySyncTable tallySyncModalOpen={tallySyncModalOpen} validatedProfile={validatedProfile} setTallySyncModalOpen={setTallySyncModalOpen} pathPlusData={path} selectedTallySyncId={selectedTallySyncId} /> : ""}

      {(stockitemNameError.length > 0 || ledgerNameError.length > 0) && pathWithoutSlash === "sales/excel" ? <ErrorModel stockitemNameError={stockitemNameError} ledgerNameError={ledgerNameError} validatedProfile={validatedProfile} /> : ""}
    </>
  );
};

export default withProfile(withRole(TransactionsTable));
