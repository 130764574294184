import React from 'react'

const PurchaseReturn = () => {
    return (
        <>
            <div className='page-content'>
                <div>PurchaseReturn</div> 
            </div>
        </>
    )
}

export default PurchaseReturn;