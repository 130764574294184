import { Button, Empty, Input, Modal, Select, Table } from "antd";
import React, { useState, useEffect } from "react";

import axios from "axios";
import { useRef } from "react";
import { CheckCircleFilled } from "@ant-design/icons";

const TallySyncTable = ({ tallySyncModalOpen, validatedProfile, setTallySyncModalOpen, pathPlusData, selectedTallySyncId }) => {
  const total = selectedTallySyncId.length;

  const [PSItem, setPSItem] = useState(0);
  const [SNItem, setSNItem] = useState(0);
  const [SYItem, setSYItem] = useState(0);
  const [ERItem, setERItem] = useState(0);
  let flagForCall = 0;
  useEffect(() => {
    if (tallySyncModalOpen && flagForCall === 0) {
      sendtoTally();
      flagForCall = 1;
    }
  }, []);

  const intervalRef = useRef(null);

  useEffect(() => {
    if (total === SYItem + ERItem) {
      if (intervalRef.current) {
        clearInterval(intervalRef.current);
      }
      return;
    }

    intervalRef.current = setInterval(() => {
      viewAll();
    }, 3000);

    return () => {
      clearInterval(intervalRef.current);
    };
  }, []);

  // console.log("selectedTallySyncId", selectedTallySyncId);
  const sendtoTally = () => {
    let data = selectedTallySyncId.map((row, index) => {
      return {
        id: row.id,
        flag: "PS",
      };
    });

    let payload = {
      userId: validatedProfile.userType === "S" ? validatedProfile.parentid : validatedProfile.userid,
      companyId: validatedProfile.companyId,
      tableName: pathPlusData,
      idColumnName: "id",
      data: data,
    };
    console.log("payload", payload);
    axios
      .post(`${process.env.REACT_APP_API_URL}/update-flag`, payload)
      .then((response) => {
        // console.log("flag response.data", response.data);
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  };

  const viewAll = () => {
    if (total > 0) {
      axios
        .post(`${process.env.REACT_APP_API_URL}/subtabledata`, {
          userId: validatedProfile.userType === "S" ? validatedProfile.parentid : validatedProfile.userid,
          companyId: validatedProfile.companyId,
        })
        .then((response) => {
          if (response.status === 200) {
            const PSItem = response.data.results[pathPlusData].filter((data) => selectedTallySyncId.some((syncdata) => syncdata.id === data.id && data.flag === "PS"));
            const SNItem = response.data.results[pathPlusData].filter((data) => selectedTallySyncId.some((syncdata) => syncdata.id === data.id && data.flag === "SN"));
            const SYItem = response.data.results[pathPlusData].filter((data) => selectedTallySyncId.some((syncdata) => syncdata.id === data.id && data.flag === "SY"));
            const ERItem = response.data.results[pathPlusData].filter((data) => selectedTallySyncId.some((syncdata) => syncdata.id === data.id && data.flag === "ER"));

            // console.log("PSItem", PSItem);
            // console.log("SNItem", SNItem);
            // console.log("SNItem.length", SNItem.length);
            // console.log("SYItem", SYItem);
            // console.log("ERItem", ERItem);

            setPSItem(PSItem.length);
            setSNItem(SNItem.length);
            setSYItem(SYItem.length);
            setERItem(ERItem.length);
          }
        })
        .catch((error) => {
          console.error("Error:", error);
        });
    }
  };

  return (
    <>
      <Modal
        className="TallySyncTable-model"
        open={tallySyncModalOpen}
        //   onCancel={() => setTallySyncModalOpen(false)}
        footer={null}
      >
        <div className="TallySyncTable-outer">
          <div className="TallySyncTable-inner">
            <div className="TallySyncTable-header">
              <p className="TallySyncTable-header-text">Your transaction are in progress</p>
            </div>
            <div className="TallySyncTable-body">
              <ul>
                <li className="TallySyncTable-body-text">
                  We are saving your transactions to tally,please follow below steps:
                  <ul>
                    <li className="TallySyncTable-body-text-sub">
                      Open <span>Tax-link's Tally connector app</span> and <span>Tally</span> on your pc/laptop
                    </li>
                    <li className="TallySyncTable-body-text-sub">Please make sure that you have selected company in Tally for which your saving data</li>
                  </ul>
                </li>
                <li>
                  <table>
                    <thead>
                      <tr>
                        <th className="text-primary">Total Saved Transaction(s)</th>
                        <th>Sync Started</th>
                        <th>In Progress</th>
                        <th className="text-success">Synced In Tally</th>
                        <th className="text-danger">Error From Synced</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td className="text-primary ">{total}</td>
                        <td>{PSItem}</td>
                        <td>{SNItem}</td>
                        <td className="text-success">{SYItem}</td>
                        <td className="text-danger">{ERItem}</td>
                      </tr>
                    </tbody>
                  </table>
                </li>

                <li className="TallySyncTable-body-text success-msg text-warning d-flex">
                  <CheckCircleFilled className={`${total === SYItem + ERItem ? "text-muted" : "text-primary"}`} />
                  <ul>
                    <li>{total} Transactions synced in Tax-link (Tally Connector App)</li>
                    <li>(Please make sure that tally is runnung on your machine and {validatedProfile.companyName} is selected.)</li>
                  </ul>
                </li>

                <li className="TallySyncTable-body-text success-msg text-success">
                  <CheckCircleFilled className={`${total === SYItem + ERItem ? "text-success" : "text-muted"}`} />
                  <span>{SYItem} Transactions successfuly synced in Tally</span>
                </li>

                <li className="TallySyncTable-body-text success-msg text-danger">
                  <CheckCircleFilled className={`${total === SYItem + ERItem ? "text-danger" : "text-muted"}`} />
                  <span>{ERItem} Transactions fail synced in Tally</span>
                </li>

                <li className="TallySyncTable-body-text">You'll be able to resume your work on this statement right afterthis sync is done.(if any)</li>
              </ul>
            </div>
            <div className="TallySyncTable-footer">
             
              <Button onClick={() => setTallySyncModalOpen(false)} type="primary">
                back to Transaction List
              </Button>
            </div>
          </div>
        </div>
      </Modal>
    </>
  );
};

export default TallySyncTable;
