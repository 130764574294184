import { HomeOutlined, FileSearchOutlined, PieChartOutlined, FolderAddOutlined, SettingOutlined, ReadOutlined, ProductOutlined, FileTextOutlined, AuditOutlined, SwapOutlined } from "@ant-design/icons";

const SidebarData = [
  {
    label: "Dashboard",
    icon: <HomeOutlined />,
    url: "/dashboard",
  },
  // {
  //   label: "Analysis",
  //   icon: <FileSearchOutlined />,
  //   url: "/analysis",
  // },
  // {
  //   label: "Reports",
  //   icon: <PieChartOutlined />,
  //   url: "/reports",
  // },
  {
    label: "Bulk Upload",
    icon: <FolderAddOutlined />,
    url: "/bulk-upload",
    isHasArrow: true,
    subItem: [
      { sublabel: "Banking", link: "/banking" },
      { sublabel: "Sales", link: "/sales/excel" },
      // { sublabel: "Sales Return", link: "/sales-return" },
      { sublabel: "Purchase", link: "/purchase/excel" },
      // { sublabel: "Purchase Return", link: "/purchase-return" },
      // { sublabel: "Journal", link: "/journal" },
      { sublabel: "Ledger", link: "/ledgerbulk" },
      { sublabel: "Items", link: "/item/excel" },
    ],
  },

  {
    label: "Transactions",
    icon: <SwapOutlined />,
    url: "/transactions",
    isHasArrow: true,
    subItem: [
      { sublabel: "Sales", link: "/tc-sales" },
      { sublabel: "Purchase", link: "/tc-purchase" },
      { sublabel: "Payment", link: "/tc-payment" },
      { sublabel: "Receipt", link: "/tc-receipt" },
      { sublabel: "Contra", link: "/tc-contra" },
    ],
  },
  {
    label: "Master",
    icon: <ProductOutlined />,
    url: "/master",
    isHasArrow: true,
    subItem: [
      { sublabel: "Ledger", link: "/ledgermaster" },
      { sublabel: "Item", link: "/item" },
      { sublabel: "Rule List", link: "/ruelist" },
    ],
  },
  {
    label: "Auto Sell",
    icon: <AuditOutlined />,
    url: "/autosale",
  },
  // {
  //   label: "GST Record",
  //   icon: <AuditOutlined />,
  //   url: "/gst-record",
  // },
  // {
  //   label: "Document",
  //   icon: <FileTextOutlined />,
  //   url: "/document",
  // },
  {
    label: "Settings",
    icon: <SettingOutlined />,
    url: "/settings",
    isHasArrow: true,
    subItem: [
      { sublabel: "My Profile", link: "/my-profile" },
      { sublabel: "My Plan", link: "/my-plan" },
      { sublabel: "Users", link: "/users" },
      { sublabel: "Refer & Earn", link: "/refer-earn" },
      { sublabel: "Role Management", link: "/role-management" },
    ],
  },

  // {
  //   label: "Learn Tax-link",
  //   icon: <ReadOutlined />,
  //   url: "/learn",
  // },
];
export default SidebarData;
